import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import analyticsData from "../../../pages/AnalyticsNew/state/analytics.module";
import dashboardData from "../../../pages/DashboardNew/state/dashboard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    analyticsData,
    dashboardData
  }
});
