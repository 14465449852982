import { fetchTotalSales, fetchCategorySales, fetchChannelSales } from '../services';

// action types
export const FETCH_TOTAL_SALES_DATA = "fetchTotalSalesData";
export const FETCH_CATEGORY_SALES_DATA = "fetchCategorySalesData";
export const FETCH_CHANNEL_SALES_DATA = "fetchChannelSalesData";

// mutation types
export const SALES_REQUEST = "setSalesRequest";
export const TOTAL_SALES_DATA_SUCCESS = "setTotalSalesDataSuccess";
export const CATEGORY_SALES_DATA_SUCCESS = "setCategorySalesDataSuccess";
export const CHANNEL_SALES_DATA_SUCCESS = "setChannelSalesDataSuccess";
export const SALES_DATA_ERROR = "setSalesDataError";
export const SALES_DATA_RESET = "setSalesDataReset";

/** Initial state */
const state = {
    loading: false,
    total_sales: null,
    category_sales: null,
    channel_sales: null,
    error: null
};

const getters = {
    totalSales(state) {
        return state.total_sales;
    },
    categorySales(state) {
        return state.category_sales;
    },
    channelSales(state) {
        return state.channel_sales;
    },
}

/**
 * Sales data mutations
 */
const mutations = {
    /** sales data request */
    [SALES_REQUEST](state) {
        Object.assign(state, { loading: true, error: null });
    },

    /** total sales data success */
    [TOTAL_SALES_DATA_SUCCESS](state, payload) {
        Object.assign(state, { loading: false, total_sales: payload });
    },

    /** category sales data success */
    [CATEGORY_SALES_DATA_SUCCESS](state, payload) {
        Object.assign(state, { loading: false, category_sales: payload });
    },
    /** channel sales data success */
    [CHANNEL_SALES_DATA_SUCCESS](state, payload) {
        Object.assign(state, { loading: false, channel_sales: payload });
    },

    /** sales data error */
    [SALES_DATA_ERROR](state, payload) {
        Object.assign(state, {
            loading: false,
            total_sales: null,
            category_sales: null,
            channel_sales: null,
            error: payload || true
        });
    },

    /** reset sales data */
    [SALES_DATA_RESET](state) {
        Object.assign(state, ...state);
    }
};

/**
 * Sales data actions
 */
const actions = {
    /** fetch total sales data */
    async [FETCH_TOTAL_SALES_DATA](context, payload) {
        context.commit(SALES_REQUEST, payload);

        const result = await fetchTotalSales(payload).catch(e => {
            context.commit(SALES_DATA_ERROR, e);
        });

        if (result) {
            context.commit(TOTAL_SALES_DATA_SUCCESS, result);
        }

        return result;
    },
    /** fetch category sales data */
    async [FETCH_CATEGORY_SALES_DATA](context, payload) {
        context.commit(SALES_REQUEST, payload);

        const result = await fetchCategorySales(payload).catch(e => {
            context.commit(SALES_DATA_ERROR, e);
        });

        if (result) {
            context.commit(CATEGORY_SALES_DATA_SUCCESS, result);
        }

        return result;
    },
    /** fetch channel sales data */
    async [FETCH_CHANNEL_SALES_DATA](context, payload) {
        context.commit(SALES_REQUEST, payload);

        const result = await fetchChannelSales(payload).catch(e => {
            context.commit(SALES_DATA_ERROR, e);
        });

        if (result) {
            context.commit(CHANNEL_SALES_DATA_SUCCESS, result);
        }

        return result;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};