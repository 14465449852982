//import md5 from 'js-md5';
const axios = require('axios');
export default {
  methods: {
showtrans(string)
{
	
	return string;
	
   /* var brand_id = localStorage.getItem('brand_id');
    var languageselected = localStorage.getItem('languageselected');
    if(brand_id=="NA" || brand_id==""){brand_id="NAA";} 
	
	var source = md5(string);
	source = brand_id+"-"+languageselected+"-"+source;
    var transsaved = JSON.parse(localStorage.getItem('transobj'));
	
    if(source in transsaved)
    {
      return transsaved[source];
    }
    else
    {
      return string;
    }*/
},
loadtrans()
{
	var brand_id = JSON.parse(localStorage.getItem('brand_id'));
	if(brand_id=="NA" || brand_id==""){brand_id="NAA";}
	if (localStorage.getItem('transobj') === null) {
	axios.get('https://api.rezcomm.com/hubtranslations/transhub?brandID='+brand_id)
	.then(response => {
	this.transobj = JSON.parse(response.request.response)
	localStorage.setItem('transobj', JSON.stringify(this.transobj))
	})
	} else {
	this.transobj = JSON.parse(localStorage.getItem('transobj'));
	}
}
  }
};