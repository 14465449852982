<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="
          aside-nav
          d-flex
          flex-column
          align-items-center
          flex-column-fluid
          py-5
          scroll scroll-pull
          ps
        "
        style="height: 528px; overflow: hidden"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->

          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Search'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
              ref="kt_search"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!-- <inline-svg src="media/svg/icons/General/Search.svg" /> -->
                <inline-svg src="media/svg/rezcommicons/Search.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>

          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Travel'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
              ref="kt_travel"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Travel.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Reservations'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 9
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="2"
              ref="kt_reservations"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Reservations.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'ECommerce'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="3"
              ref="kt_ecommerce"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Ecommerce.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Venue'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="4"
              ref="kt_venue"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Venue.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Customers'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="5"
              ref="kt_customers"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Customers.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Marketing'"
            v-if="
              loggeduser_groupid != 7 &&
              loggeduser_groupid != 9 &&
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              ref="kt_marketing"
              data-tab="6"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Marketing.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Business'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="7"
              ref="kt_business"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Business.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Logout'"
          >
            <a
              href="/logout.html"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="8"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/rezcommicons/Logout.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="
          aside-footer
          d-flex
          flex-column
          align-items-center
          flex-column-auto
          py-4 py-lg-10
        "
      >
        <!--begin::Aside Toggle-->
        <span
          class="
            aside-toggle
            btn btn-icon btn-primary btn-hover-primary
            shadow-sm
          "
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->

        <!-- <KTQuickPanel></KTQuickPanel> -->

        <!--end::Quick Panel-->
        <!--begin::User-->
        <!-- <KTQuickUser></KTQuickUser> -->
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->

    <div class="aside-secondary d-flex flex-row-fluid">
      <!--begin::Workspace-->
      <div
        class="aside-workspace scroll scroll-push my-2 ps"
        style="height: 824px; overflow: hidden"
      >
        <!-- rezcomm-logo-large.png -->
        <div class="py-1 px-12 py-lg-8 d-flex flex-left">
          <img
            src="media/logos/rezcomm-logo-large.png"
            alt="large logo"
            style="width: auto; max-height: 40px"
          />
        </div>

        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->

            <template v-slot:activator="{ on, attrs }">
              <v-btn text fab small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-database-search</v-icon>
              </v-btn>
            </template>

            <v-card
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 13 && loggeduser_groupid != 14 &&
                loggeduser_groupid != 10
              "
            >
              <v-card-title class="headline grey lighten-2">
                Search Menu Items
              </v-card-title>

              <v-card-text>
                <v-flex xs12 text-xs-right>
                  <v-autocomplete
                    v-model="ses_menu"
                    :items="ses_menu_options"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label="Select"
                    item-text="name"
                    item-value="link"
                    item-group="group"
                    :filter="customFilter"
                    @change="showsearch"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="checkTypeOf(data.item)">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>

                      <template v-else>
                        <v-list-item-content
                          ><v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title
                          ><v-list-item-subtitle
                            v-html="data.item.group"
                          ></v-list-item-subtitle
                        ></v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-card-text>
            </v-card>

            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTMenu></KTMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu_reservation"
                id="kt_aside_menu_reservation"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTReservationMenu
                    :showactiverezmenu="showactiverezmenu"
                    :showactiverezsubmenu="showactiverezsubmenu"
                  ></KTReservationMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu_ecomm"
                id="kt_aside_menu_ecomm"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTECommerceMenu></KTECommerceMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu_venue"
                id="kt_aside_menu_venue"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTVenueMenu></KTVenueMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu_cust"
                id="kt_aside_menu_cust"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTCustomerMenu></KTCustomerMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu_mark"
                id="kt_aside_menu_mark"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTMarketingMenu
                    :showactiverezmenu="showactiverezmenu"
                  ></KTMarketingMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-4 py-4"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->

              <div
                ref="kt_aside_menu_busi"
                id="kt_aside_menu_busi"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTBusinessMenu
                    :showactiverezmenu="showactiverezmenu"
                    :showactiverezsubmenu="showactiverezsubmenu"
                  ></KTBusinessMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
.aside {
  position: relative;
}
</style>

<script>
import { mapGetters } from "vuex";
const axios = require("axios");
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideMenuReser from "@/assets/js/layout/base/aside-menu-reserv.js";
import KTLayoutAsideMenuMark from "@/assets/js/layout/base/aside-menu-mark.js";
import KTLayoutAsideMenuEcom from "@/assets/js/layout/base/aside-menu-ecom.js";
import KTLayoutAsideMenuBusi from "@/assets/js/layout/base/aside-menu-busi.js";
import KTLayoutAsideMenuVenu from "@/assets/js/layout/base/aside-menu-venue.js";
import KTLayoutAsideMenuCust from "@/assets/js/layout/base/aside-menu-cust.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import KTReservationMenu from "@/view/layout/aside/ReservationMenu.vue";
import KTMarketingMenu from "@/view/layout/aside/MarketingMenu.vue";
import KTECommerceMenu from "@/view/layout/aside/ECommerceMenu.vue";
import KTVenueMenu from "@/view/layout/aside/VenueMenu.vue";
import KTCustomerMenu from "@/view/layout/aside/CustomerMenu.vue";
import KTBusinessMenu from "@/view/layout/aside/BusinessMenu.vue";
import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

export default {
  name: "KTAside",
  props: ["tabindextoshow", "showactiverezmenu", "showactiverezsubmenu"],
  data() {
    return {
      insideTm: 0,
      loggeduser_groupid: 0,
      outsideTm: 0,
      tabIndex: 0,
      menuref: [
        "kt_search",
        "kt_travel",
        "kt_reservations",
        "kt_ecommerce",
        "kt_venue",
        "kt_customers",
        "kt_marketing",
        "kt_business",
      ],
      searchmenudialog: false,
      ses_menu_options: [],
      ses_menu: [],
    };
  },
  components: {
    KTBrand,
    KTMenu,
    KTReservationMenu,
    KTECommerceMenu,
    KTVenueMenu,
    KTCustomerMenu,
    KTMarketingMenu,
    KTBusinessMenu,
    //KTQuickActions,
    //KTQuickUser,
    // KTQuickPanel
  },
  mounted() {
    var varToken = localStorage.getItem("x_token");
    if (varToken === null) {
      window.location.href = "/index.html";
    }

    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);
      KTLayoutAsideMenuReser.init(this.$refs["kt_aside_menu_reservation"]);
      KTLayoutAsideMenuMark.init(this.$refs["kt_aside_menu_mark"]);
      KTLayoutAsideMenuEcom.init(this.$refs["kt_aside_menu_ecomm"]);
      KTLayoutAsideMenuBusi.init(this.$refs["kt_aside_menu_busi"]);
      KTLayoutAsideMenuVenu.init(this.$refs["kt_aside_menu_venue"]);
      KTLayoutAsideMenuCust.init(this.$refs["kt_aside_menu_cust"]);
      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      let aa = this.menuref[this.tabindextoshow];
      // console.log(aa);

      this.tabIndex = parseInt(this.tabindextoshow);
      if (this.$refs[aa]) {
        this.$refs[aa].click();
      }

      var varTokenaa = localStorage.getItem("x_token");

      var data = "";
      axios
        .post("https://devhub.rezcomm.com/hubapmydbauth/menusearch.php", data, {
          headers: { Authorization: "Bearer " + varTokenaa },
        })
        .then((response) => {
          this.codesent = JSON.parse(response.request.response);
          if (this.codesent.user_exists == "Y") {
            this.ses_menu_options = this.codesent.clients;
          } else {
            //window.location.href="/index.html";
          }
        });
    });
    let grpid = localStorage.getItem("group_id");
    grpid = grpid.replace('"', "");
    grpid = grpid.replace('"', "");
	this.loggeduser_groupid=grpid;
    let brand_id_logged = localStorage.getItem("brand_id");
    brand_id_logged = brand_id_logged.replace('"', "");	
    brand_id_logged = brand_id_logged.replace('"', "");	
    //console.log('this.loggeduser_groupid',this.loggeduser_groupid);
    //console.log('brand_id_logged',brand_id_logged);
	if(this.loggeduser_groupid=="13")
	{
	let wcl = window.location.pathname;
		if(wcl!='/dash.html' && wcl!='/m6mepuat.html' && wcl!='/m6utr.html' && wcl!='/logout.html')
		{
		window.location.href="/index.html";
		}
	
	}
	
	if(this.loggeduser_groupid=="9" && brand_id_logged=="M6T")
	{
	//console.log('window.location.pathname',window.location.pathname);
		let wcl = window.location.pathname;
		if(wcl!='/dash.html' && wcl!='/m6mepuat.html' && wcl!='/m6utr.html' && wcl!='/logout.html' && wcl!='/m6tagsuat.html' && wcl!='/m6contactuat.html' && wcl!='/M6tagsregnoupdate.html' && wcl!='/M6tagsreplace.html' && wcl!='/M6tagsreqnew.html' && wcl!='/M6toll.html' && wcl!='/M6tollTransactions.html' && wcl!='/unblockuser.html')
		{
		window.location.href="/index.html";
		}
	}	
	  

    //console.log("loggeduser_groupid==" + this.loggeduser_groupid);
  },
  methods: {
    checkTypeOf(val) {
      if (typeof val !== "object") {
        return true;
      }
      return false;
    },
    setActiveSubmenu() {
      console.log("setActiveSubmenu");

      let pageURL, pageTitle;
      pageURL = window.location.href;
      let title_el = document.querySelector("#kt_subheader .subheader-title");
      if (title_el) {
        pageTitle = title_el.innerText.trim();
      }
      let menuItems = [
        ...document.querySelectorAll(
          ".menu-subnav .menu-item.menu-item-active .menu-link"
        ),
      ];

      // console.log("setActiveSubmenu");
      // console.log(menuItems);

      menuItems.map((menuItem) => {
        // console.log(menuItem.href);
        // console.log(menuItem.innerText);

        if (pageURL.indexOf(menuItem.href) > -1) {
          menuItem.classList.add("active-submenu-item");
          menuItem.querySelector(".menu-text").style =
            "font-weight: bold !important;";
        } else if (pageTitle === menuItem.innerText.trim()) {
          menuItem.classList.add("active-submenu-item");
          menuItem.querySelector(".menu-text").style =
            "font-weight: bold !important;";
        }
      });
    },

    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");

      this.setActiveSubmenu();
    },
    customFilter: function (item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.group.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    showsearch() {
      let sesmenu = this.ses_menu;
      if (sesmenu == "") {
        alert("Link Not Yet Configured");
      } else {
        this.gotoUrl(sesmenu);
      }
    },
    gotoUrl(place) {
      window.location.href = place;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
