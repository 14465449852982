import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#52529d",
        secondary: "#F0F5FA",
        accent: "#685CA9",
        error: "#BF181A",
        info: "#007DA8",
        success: "#6E992E",
        warning: "#F9AF27"
      }
    }
  }
});
