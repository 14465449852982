<template>
  <div class="preloader">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Preloader",
  props: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loader-ring {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 8px;
  border: 8px solid #52529d;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #52529d transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>