import axios from 'axios';
import { httpClient } from '@/core/services';
import { apiConstants } from '@/core/config';

const auth_token = { Authorization: 'Bearer ' + localStorage.getItem('x_token')};
const api_url = "https://devhub.rezcomm.com/hubapmydbauth/";

export const fetchTotalSales = () => {
    const url = apiConstants.total_sales_php;    
    
    return axios.get(`${api_url}${url}`, {
        params: '', headers: auth_token
    }).then(res => res.data)
        .catch(() => { throw 'Data Loading Error'; });
};

export const fetchCategorySales = () => {
    const url = apiConstants.category_sales_php;

    return axios.get(`${api_url}${url}`, {
        params: '', headers: auth_token
    }).then(res => res.data)
        .catch(() => { throw 'Data Loading Error'; });
};

export const fetchChannelSales = () => {
    const url = apiConstants.channel_sales_php;

    return axios.get(`${api_url}${url}`, {
        params: '', headers: auth_token
    }).then(res => res.data)
        .catch(() => { throw 'Data Loading Error'; });
};
