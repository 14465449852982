<template>
  <v-app class>
    <v-container
      fluid
      fill-height
      style="
        margin: 0px;
        padding: 0px;
        width: 100%;
        min-height: 100%;
        display: block;
      "
    >
      <v-layout>
        <!---------------------Main Page Area Starts--------------------->

        <v-container
          fluid
          fill-height
          style="
            margin: 0px;
            padding: 0px;
            width: 100%;
            min-height: 100%;
            display: block;
          "
        >
          <v-layout>
            <div class="container3" style="height: 100%">
              <div id="people">
                <v-layout text-right wrap> </v-layout>

                <v-layout wrap>
                  <v-card style="width: 100%" class="elevation-0">
                    <v-toolbar flat>
                      <v-toolbar-title
                        >{{ showtrans("Car Park Details") }} -
                        {{ carparkdetails_name }}
                      </v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>

                    <v-card-text class="pt-6">
                      <v-form
                        ref="carparkdetails_form"
                        id="carparkdetails_form"
                      >
                        <div class="kit-status">
                          <v-row class="pb-8">
                            <v-col col="3" class="py-0">
                              <v-file-input
                                chips
                                label="Grid Image"
                                :rules="rules"
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                v-model="grid_image"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-file-input>
                              <div>
                                <div>
                                  Existing Grid Image:
                                  <a :href="grid_image_exist" target="_blank"
                                    >View</a
                                  >
                                </div>

                                <img
                                  :src="grid_image_exist"
                                  alt=""
                                  width="200px"
                                  height="auto"
                                  style="margin-top: 1rem"
                                />
                              </div>
                            </v-col>

                            <v-col col="3" class="py-0">
                              <v-file-input
                                chips
                                label="List Image"
                                :rules="rules"
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                v-model="list_image"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-file-input
                              >
                              <div>
                                <div>
                                  Existing List Image:
                                  <a :href="list_image_exist" target="_blank"
                                    >View</a
                                  >
                                </div>

                                <img
                                  :src="list_image_exist"
                                  alt=""
                                  width="200px"
                                  height="auto"
                                  style="margin-top: 1rem"
                                />
                              </div>
                            </v-col>

                            <v-col col="3" class="py-0">
                              <v-file-input
                                chips
                                label="Map Image"
                                :rules="rules"
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                v-model="map_pdf"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-file-input
                              >
                              <div>
                                <div>
                                  Existing Map Image:
                                  <a :href="map_pdf_exist" target="_blank"
                                    >View</a
                                  >
                                </div>

                                <img
                                  :src="map_pdf_exist"
                                  alt=""
                                  width="200px"
                                  height="auto"
                                  style="margin-top: 1rem"
                                />
                              </div>
                            </v-col>

                            <v-col col="3" class="py-0">
                              <v-file-input
                                chips
                                label="More Info Image"
                                :rules="rules"
                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                v-model="map_pdf"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-file-input
                              >
                              <div>
                                <div>
                                  Existing More Info Image:
                                  <a :href="map_pdf_exist" target="_blank"
                                    >View</a
                                  >
                                </div>

                                <img
                                  :src="map_pdf_exist"
                                  alt=""
                                  width="200px"
                                  height="auto"
                                  style="margin-top: 1rem"
                                />
                              </div>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col col="4">
                              <v-textarea
                                outlined
                                name="feature_1"
                                label="Feature List 1"
                                v-model="feature_1"
                                rows="5"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >
                            </v-col>
                            <v-col col="4">
                              <v-textarea
                                outlined
                                name="feature_2"
                                label="Feature List 2"
                                v-model="feature_2"
                                rows="5"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >
                            </v-col>

                            <v-col col="4">
                              <v-textarea
                                outlined
                                name="feature_3"
                                label="Feature List 3"
                                v-model="feature_3"
                                rows="5"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >
                              <v-textarea
                                style="display: none"
                                outlined
                                name="feature_5"
                                label="Feature List 5 not used"
                                v-model="feature_5"
                                rows="5"
                              ></v-textarea>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col col="6" class="py-0">
                              <v-text-field
                                outlined
                                label="Tag Line"
                                name="feature_4"
                                maxlength="150"
                                v-model="feature_4"
                              >
                                <template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-text-field
                              >
                            </v-col>

                            <v-col col="6" class="py-0">
                              <v-text-field
                                outlined
                                label="Distance in time from Terminal"
                                name="distance_in_time_1"
                                maxlength="150"
                                v-model="distance_in_time_1"
                                ><template v-slot:append-outer>
                                  <v-slide-x-reverse-transition
                                    mode="out-in"
                                  ></v-slide-x-reverse-transition>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Lorem ipsum dolor sit amet consectetur
                                      adipisicing elit. Officiis repellendus
                                      dolore illum commodi vel error adipisci id
                                      omnis fuga nisi est, laborum voluptatibus
                                      qui eaque corrupti dicta, tempore rem
                                      quae!
                                    </div>
                                  </v-tooltip>
                                </template></v-text-field
                              >
                            </v-col>
                          </v-row>
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-divider class="m-0"></v-divider>
                    <v-card-actions class="grey lighten-5">
                      <v-spacer></v-spacer>
                      <div class="d-flex">
                        <v-btn color="primary" @click="update_carparkdetails">
                          Update
                        </v-btn>
                        <v-btn
                          color="grey"
                          outlined
                          depressed
                          @click="gotoUrl('/carparkingservice.html')"
                          class="ml-2 lighten-2"
                        >
                          Back
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-layout>

                <v-layout v-if="showdatagrid" wrap>
                  <v-client-table
                    :data="tableData"
                    :columns="columns"
                    :options="options"
                    class="v-data-table theme--light"
                    ref="vuetable"
                  >
                    <span slot="actions" slot-scope="{ row }">
                      <button
                        v-on:click="editcarpark(row.carpark_id, row.venue_id)"
                      >
                        <v-icon>mdi-pencil-box</v-icon>
                      </button>
                      <!---<button title="CPMS Carpark Mapping" v-on:click="mapcpmscarpark(row.carpark_id, row.carpark_name,row.venue_id)"><v-icon>mdi-map-marker-circle</v-icon></button>--->
                      <button
                        title="Carpark Details"
                        v-on:click="
                          carparkdetails(row.carpark_id, row.carpark_name)
                        "
                      >
                        <v-icon>mdi-details</v-icon>
                      </button>
                      <button
                        v-on:click="deletecarpark(row.carpark_id, row.venue_id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </button>
                    </span>
                  </v-client-table>
                </v-layout>

                <v-layout v-if="showaddbrand" wrap>
                  <v-content>
                    <v-container class="fill-height" fluid>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="12" md="12">
                          <v-card class="elevation-3">
                            <v-toolbar color="primary" dark flat>
                              <v-toolbar-title>{{
                                showtrans("Add Car Parking Service")
                              }}</v-toolbar-title>
                              <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                              <v-text-field
                                :label="showtrans('Carpark Name')"
                                name="carpark_name"
                                maxlength="25"
                                v-model="carpark_name"
                                v-bind:class="{
                                  textdanger: carpark_name_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Name - The name of the car park.
                                      This field is mandatory.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>

                              <v-text-field
                                :label="showtrans('Carpark Identifier')"
                                name="carpark_identifier"
                                maxlength="25"
                                v-model="carpark_identifier"
                                v-bind:class="{
                                  textdanger: carpark_identifier_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Identifier - The identifier of the
                                      carpark in most cases 3 or 4 digits. If
                                      this is an existing client then best to
                                      check if they already use an identifier
                                      for the car park in question and then use
                                      the same identifier. If they don't have
                                      one then please make up sensible
                                      identifiers. For example if they have a
                                      car park called Car Park 1 - perhaps CP1
                                      is the simplest identifier as opposed to
                                      YU098MG847. Stick to the KISS principle
                                      when in doubt but it still in doubt ask
                                      the Rezcomm Team. This field is mandatory
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Total Parking Slots')"
                                name="total_parking_slots"
                                maxlength="10"
                                type="number"
                                min="0"
                                v-model="total_parking_slots"
                                v-bind:class="{
                                  textdanger: total_parking_slots_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      The number of parking slots at the car
                                      park. This field is mandatory and must be
                                      greater than 0. This field once added is
                                      not editable via the edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Slot Duration in minutes')"
                                name="slot_duration_in_minutes"
                                maxlength="10"
                                type="number"
                                min="0"
                                v-model="slot_duration_in_minutes"
                                v-bind:class="{
                                  textdanger: slot_duration_in_minutes_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Slot duration in minutes - This is the
                                      minimum duration one can book parking for.
                                      Your options are 15/30/45/60 minutes. This
                                      is a mandatory field. Once this has been
                                      set this field cannot be changed or
                                      edited. This is a mandatory field. This
                                      field once added is not editable via the
                                      edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Entry Grace Time - Hours')
                                    "
                                    name="entry_grace_hours"
                                    v-bind:class="{
                                      textdanger: entry_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of entry for
                                          parking to start. So if set to 1 hour
                                          and the customer books for 10.00am.
                                          They will still be allowed to enter if
                                          they try to access parking at 9.00am
                                          without having to pay an additional
                                          fee. In some cases please note that
                                          this setting is managed at the CPMS
                                          level and if you are entering data for
                                          such a customer this value needs to be
                                          set to 0 and any grace will get added
                                          at CPMS end. This is a mandatory
                                          field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Entry Grace Time - Minutes')
                                    "
                                    name="entry_grace_minutes"
                                    v-bind:class="{
                                      textdanger: entry_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual booked time of entry for
                                          parking to start. So if set to 30
                                          minutes with the above grace time
                                          hours set to 1 hour and the customer
                                          books for 10.00am. They will still be
                                          allowed to enter if they try to access
                                          parking at 8.30am without having to
                                          pay an additional fee. In some cases
                                          please note that this setting is
                                          managed at the CPMS level and if you
                                          are entering data for such a customer
                                          this value needs to be set to 0 and
                                          any grace will get added at CPMS end.
                                          This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Exit Grace Time - Hours')
                                    "
                                    name="exit_grace_hours"
                                    v-bind:class="{
                                      textdanger: exit_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of exit for parking
                                          to start. So if set to 1 hour and the
                                          customer books for 10.00am. They will
                                          still be allowed to enter if they try
                                          to access parking at 9.00am without
                                          having to pay an additional fee. In
                                          some cases please note that this
                                          setting is managed at the CPMS level
                                          and if you are entering data for such
                                          a customer this value needs to be set
                                          to 0 and any grace will get added at
                                          CPMS end. This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Exit Grace Time - Minutes')
                                    "
                                    name="exit_grace_minutes"
                                    v-bind:class="{
                                      textdanger: exit_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual time of entry for parking
                                          to start. So if set to 30 minutes with
                                          the above grace time hours set to 1
                                          hour and the customer books for
                                          10.00am. They will still be allowed to
                                          enter if they try to access parking at
                                          8.30am without having to pay an
                                          additional fee. In some cases please
                                          note that this setting is managed at
                                          the CPMS level and if you are entering
                                          data for such a customer this value
                                          needs to be set to 0 and any grace
                                          will get added at CPMS end. This is a
                                          mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-select
                                v-model="visible_on_website"
                                :items="visible_on_website_options"
                                :label="showtrans('Visible on website')"
                                name="visible_on_website"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{
                                  textdanger: visible_on_website_hasError,
                                }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Visible on website - This is the field use
                                      to control if the said car park is visible
                                      on the front end of the website or not.
                                      Its a quick way to hide the car park from
                                      search results while still making it
                                      available for third parties via APIs. Use
                                      with caution. This is a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >
                              <v-textarea
                                outlined
                                name="description"
                                label="Carpark Description"
                                v-model="description"
                                v-bind:class="{
                                  textdanger: description_hasError,
                                }"
                                rows="5"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Car Park Description - This is where you
                                      can enter some blurb about the car park.
                                      At this point this field is not used
                                      anywhere in the front end but can be used
                                      in the future if such a need arises. This
                                      field is not mandatory.
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >
                              <v-select
                                v-model="status"
                                :items="status_options"
                                :label="showtrans('Status')"
                                name="status"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{ textdanger: status_hasError }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Status - This is used to
                                      activate/deactivate an entire car park and
                                      can completely end up stopping parking
                                      search on the front end. So be careful if
                                      you choose to de-activate. However if you
                                      are setting up the client in dev / uat
                                      mode then you are ok to tinker with this.
                                      If in live then use with caution. This is
                                      a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn @click="createcarpark" color="primary">{{
                                showtrans("Submit")
                              }}</v-btn>
                              <v-btn @click="getcarpark" color="info">{{
                                showtrans("Back")
                              }}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-content>
                </v-layout>
                <v-layout v-if="showeditclient" wrap>
                  <v-content>
                    <v-container class="fill-height" fluid>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="12" md="12">
                          <v-card class="elevation-3">
                            <v-toolbar color="primary" dark flat>
                              <v-toolbar-title>{{
                                showtrans("Edit Car Park")
                              }}</v-toolbar-title>
                              <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                              <v-text-field
                                :label="showtrans('Carpark Name')"
                                name="carpark_name"
                                maxlength="25"
                                v-model="carpark_name"
                                v-bind:class="{
                                  textdanger: carpark_name_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Name - The name of the car park.
                                      This field is mandatory.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>

                              <v-text-field
                                :label="showtrans('Carpark Identifier')"
                                name="carpark_identifier"
                                maxlength="25"
                                v-model="carpark_identifier"
                                v-bind:class="{
                                  textdanger: carpark_identifier_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Identifier - The identifier of the
                                      carpark in most cases 3 or 4 digits. If
                                      this is an existing client then best to
                                      check if they already use an identifier
                                      for the car park in question and then use
                                      the same identifier. If they don't have
                                      one then please make up sensible
                                      identifiers. For example if they have a
                                      car park called Car Park 1 - perhaps CP1
                                      is the simplest identifier as opposed to
                                      YU098MG847. Stick to the KISS principle
                                      when in doubt but it still in doubt ask
                                      the Rezcomm Team. This field is mandatory
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Total Parking Slots')"
                                name="total_parking_slots"
                                maxlength="10"
                                type="number"
                                min="0"
                                readonly
                                v-model="total_parking_slots"
                                v-bind:class="{
                                  textdanger: total_parking_slots_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      The number of parking slots at the car
                                      park. This field is mandatory and must be
                                      greater than 0. This field once added is
                                      not editable via the edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Slot Duration in minutes')"
                                name="slot_duration_in_minutes"
                                maxlength="10"
                                type="number"
                                min="0"
                                readonly
                                v-model="slot_duration_in_minutes"
                                v-bind:class="{
                                  textdanger: slot_duration_in_minutes_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Slot duration in minutes - This is the
                                      minimum duration one can book parking for.
                                      Your options are 15/30/45/60 minutes. This
                                      is a mandatory field. Once this has been
                                      set this field cannot be changed or
                                      edited. This is a mandatory field. This
                                      field once added is not editable via the
                                      edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Entry Grace Time - Hours')
                                    "
                                    name="entry_grace_hours"
                                    v-bind:class="{
                                      textdanger: entry_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of entry for
                                          parking to start. So if set to 1 hour
                                          and the customer books for 10.00am.
                                          They will still be allowed to enter if
                                          they try to access parking at 9.00am
                                          without having to pay an additional
                                          fee. In some cases please note that
                                          this setting is managed at the CPMS
                                          level and if you are entering data for
                                          such a customer this value needs to be
                                          set to 0 and any grace will get added
                                          at CPMS end. This is a mandatory
                                          field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Entry Grace Time - Minutes')
                                    "
                                    name="entry_grace_minutes"
                                    v-bind:class="{
                                      textdanger: entry_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual booked time of entry for
                                          parking to start. So if set to 30
                                          minutes with the above grace time
                                          hours set to 1 hour and the customer
                                          books for 10.00am. They will still be
                                          allowed to enter if they try to access
                                          parking at 8.30am without having to
                                          pay an additional fee. In some cases
                                          please note that this setting is
                                          managed at the CPMS level and if you
                                          are entering data for such a customer
                                          this value needs to be set to 0 and
                                          any grace will get added at CPMS end.
                                          This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Exit Grace Time - Hours')
                                    "
                                    name="exit_grace_hours"
                                    v-bind:class="{
                                      textdanger: exit_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of exit for parking
                                          to start. So if set to 1 hour and the
                                          customer books for 10.00am. They will
                                          still be allowed to enter if they try
                                          to access parking at 9.00am without
                                          having to pay an additional fee. In
                                          some cases please note that this
                                          setting is managed at the CPMS level
                                          and if you are entering data for such
                                          a customer this value needs to be set
                                          to 0 and any grace will get added at
                                          CPMS end. This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Exit Grace Time - Minutes')
                                    "
                                    name="exit_grace_minutes"
                                    v-bind:class="{
                                      textdanger: exit_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual time of entry for parking
                                          to start. So if set to 30 minutes with
                                          the above grace time hours set to 1
                                          hour and the customer books for
                                          10.00am. They will still be allowed to
                                          enter if they try to access parking at
                                          8.30am without having to pay an
                                          additional fee. In some cases please
                                          note that this setting is managed at
                                          the CPMS level and if you are entering
                                          data for such a customer this value
                                          needs to be set to 0 and any grace
                                          will get added at CPMS end. This is a
                                          mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-select
                                v-model="visible_on_website"
                                :items="visible_on_website_options"
                                :label="showtrans('Visible on website')"
                                name="visible_on_website"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{
                                  textdanger: visible_on_website_hasError,
                                }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Visible on website - This is the field use
                                      to control if the said car park is visible
                                      on the front end of the website or not.
                                      Its a quick way to hide the car park from
                                      search results while still making it
                                      available for third parties via APIs. Use
                                      with caution. This is a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >

                              <v-textarea
                                outlined
                                name="description"
                                label="Carpark Description"
                                v-model="description"
                                v-bind:class="{
                                  textdanger: description_hasError,
                                }"
                                rows="5"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Car Park Description - This is where you
                                      can enter some blurb about the car park.
                                      At this point this field is not used
                                      anywhere in the front end but can be used
                                      in the future if such a need arises. This
                                      field is not mandatory.
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >

                              <v-select
                                v-model="status"
                                :items="status_options"
                                :label="showtrans('Status')"
                                name="status"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{ textdanger: status_hasError }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Status - This is used to
                                      activate/deactivate an entire car park and
                                      can completely end up stopping parking
                                      search on the front end. So be careful if
                                      you choose to de-activate. However if you
                                      are setting up the client in dev / uat
                                      mode then you are ok to tinker with this.
                                      If in live then use with caution. This is
                                      a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn @click="updatecarpark" color="primary">{{
                                showtrans("Update")
                              }}</v-btn>
                              <v-btn @click="getcarpark" color="info">{{
                                showtrans("Back")
                              }}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-content>
                </v-layout>
                <v-layout v-if="showdeleteclient" wrap>
                  <v-content>
                    <v-container class="fill-height" fluid>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="12" md="12">
                          <v-card class="elevation-3">
                            <v-toolbar color="primary" dark flat>
                              <v-toolbar-title>{{
                                showtrans("Delete Car Parking Service")
                              }}</v-toolbar-title>
                              <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                              <v-text-field
                                :label="showtrans('Carpark Name')"
                                name="carpark_name"
                                maxlength="25"
                                v-model="carpark_name"
                                v-bind:class="{
                                  textdanger: carpark_name_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Name - The name of the car park.
                                      This field is mandatory.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>

                              <v-text-field
                                :label="showtrans('Carpark Identifier')"
                                name="carpark_identifier"
                                maxlength="25"
                                v-model="carpark_identifier"
                                v-bind:class="{
                                  textdanger: carpark_identifier_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Carpark Identifier - The identifier of the
                                      carpark in most cases 3 or 4 digits. If
                                      this is an existing client then best to
                                      check if they already use an identifier
                                      for the car park in question and then use
                                      the same identifier. If they don't have
                                      one then please make up sensible
                                      identifiers. For example if they have a
                                      car park called Car Park 1 - perhaps CP1
                                      is the simplest identifier as opposed to
                                      YU098MG847. Stick to the KISS principle
                                      when in doubt but it still in doubt ask
                                      the Rezcomm Team. This field is mandatory
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Total Parking Slots')"
                                name="total_parking_slots"
                                maxlength="10"
                                type="number"
                                min="0"
                                v-model="total_parking_slots"
                                v-bind:class="{
                                  textdanger: total_parking_slots_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      The number of parking slots at the car
                                      park. This field is mandatory and must be
                                      greater than 0. This field once added is
                                      not editable via the edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-text-field
                                :label="showtrans('Slot Duration in minutes')"
                                name="slot_duration_in_minutes"
                                maxlength="10"
                                type="number"
                                min="0"
                                v-model="slot_duration_in_minutes"
                                v-bind:class="{
                                  textdanger: slot_duration_in_minutes_hasError,
                                }"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Slot duration in minutes - This is the
                                      minimum duration one can book parking for.
                                      Your options are 15/30/45/60 minutes. This
                                      is a mandatory field. Once this has been
                                      set this field cannot be changed or
                                      edited. This is a mandatory field. This
                                      field once added is not editable via the
                                      edit page.
                                    </div>
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Entry Grace Time - Hours')
                                    "
                                    name="entry_grace_hours"
                                    v-bind:class="{
                                      textdanger: entry_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of entry for
                                          parking to start. So if set to 1 hour
                                          and the customer books for 10.00am.
                                          They will still be allowed to enter if
                                          they try to access parking at 9.00am
                                          without having to pay an additional
                                          fee. In some cases please note that
                                          this setting is managed at the CPMS
                                          level and if you are entering data for
                                          such a customer this value needs to be
                                          set to 0 and any grace will get added
                                          at CPMS end. This is a mandatory
                                          field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="entry_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Entry Grace Time - Minutes')
                                    "
                                    name="entry_grace_minutes"
                                    v-bind:class="{
                                      textdanger: entry_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Entry Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual booked time of entry for
                                          parking to start. So if set to 30
                                          minutes with the above grace time
                                          hours set to 1 hour and the customer
                                          books for 10.00am. They will still be
                                          allowed to enter if they try to access
                                          parking at 8.30am without having to
                                          pay an additional fee. In some cases
                                          please note that this setting is
                                          managed at the CPMS level and if you
                                          are entering data for such a customer
                                          this value needs to be set to 0 and
                                          any grace will get added at CPMS end.
                                          This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-row>
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_hours"
                                    :items="timeinhours"
                                    :label="
                                      showtrans('Exit Grace Time - Hours')
                                    "
                                    name="exit_grace_hours"
                                    v-bind:class="{
                                      textdanger: exit_grace_hours_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Hours - This is the
                                          grace time "hour" allowed from their
                                          actual booked time of exit for parking
                                          to start. So if set to 1 hour and the
                                          customer books for 10.00am. They will
                                          still be allowed to enter if they try
                                          to access parking at 9.00am without
                                          having to pay an additional fee. In
                                          some cases please note that this
                                          setting is managed at the CPMS level
                                          and if you are entering data for such
                                          a customer this value needs to be set
                                          to 0 and any grace will get added at
                                          CPMS end. This is a mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                                <v-col
                                  ><v-select
                                    v-model="exit_grace_minutes"
                                    :items="timeminutes"
                                    :label="
                                      showtrans('Exit Grace Time - Minutes')
                                    "
                                    name="exit_grace_minutes"
                                    v-bind:class="{
                                      textdanger: exit_grace_minutes_hasError,
                                    }"
                                    ><template v-slot:append-outer>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-icon v-on="on">
                                            mdi-help-circle-outline
                                          </v-icon>
                                        </template>
                                        <div style="width: 50ch">
                                          Exit Grace Time Minutes - This is the
                                          grace time "minutes" allowed from
                                          their actual time of entry for parking
                                          to start. So if set to 30 minutes with
                                          the above grace time hours set to 1
                                          hour and the customer books for
                                          10.00am. They will still be allowed to
                                          enter if they try to access parking at
                                          8.30am without having to pay an
                                          additional fee. In some cases please
                                          note that this setting is managed at
                                          the CPMS level and if you are entering
                                          data for such a customer this value
                                          needs to be set to 0 and any grace
                                          will get added at CPMS end. This is a
                                          mandatory field.
                                        </div>
                                      </v-tooltip>
                                    </template></v-select
                                  ></v-col
                                >
                              </v-row>
                              <v-select
                                v-model="visible_on_website"
                                :items="visible_on_website_options"
                                :label="showtrans('Visible on website')"
                                name="visible_on_website"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{
                                  textdanger: visible_on_website_hasError,
                                }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Visible on website - This is the field use
                                      to control if the said car park is visible
                                      on the front end of the website or not.
                                      Its a quick way to hide the car park from
                                      search results while still making it
                                      available for third parties via APIs. Use
                                      with caution. This is a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >
                              <v-textarea
                                outlined
                                name="description"
                                label="Carpark Description"
                                v-model="description"
                                v-bind:class="{
                                  textdanger: description_hasError,
                                }"
                                rows="5"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Car Park Description - This is where you
                                      can enter some blurb about the car park.
                                      At this point this field is not used
                                      anywhere in the front end but can be used
                                      in the future if such a need arises. This
                                      field is not mandatory.
                                    </div>
                                  </v-tooltip>
                                </template></v-textarea
                              >
                              <v-select
                                v-model="status"
                                :items="status_options"
                                :label="showtrans('Status')"
                                name="status"
                                item-text="name"
                                item-value="code"
                                v-bind:class="{ textdanger: status_hasError }"
                                ><template v-slot:append-outer>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <div style="width: 50ch">
                                      Status - This is used to
                                      activate/deactivate an entire car park and
                                      can completely end up stopping parking
                                      search on the front end. So be careful if
                                      you choose to de-activate. However if you
                                      are setting up the client in dev / uat
                                      mode then you are ok to tinker with this.
                                      If in live then use with caution. This is
                                      a mandatory field.
                                    </div>
                                  </v-tooltip>
                                </template></v-select
                              >
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn @click="removecarpark" color="primary">{{
                                showtrans("Confirm Delete")
                              }}</v-btn>
                              <v-btn @click="getcarpark" color="info">{{
                                showtrans("Back")
                              }}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-content>
                </v-layout>
              </div>
            </div>
          </v-layout>
        </v-container>

        <!---------------------Main Page Area Ends--------------------->

        <!-- <v-dialog v-model="carparkdetails_modal" max-width="1000"> </v-dialog> -->
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import translationHub from "@/mixins/translationsHub";

const axios = require("axios");
const crypto = require("crypto");
export default {
  name: "CarparkingService",
  mixins: [translationHub],
  components: {},
  methods: {
    gotoUrl(place) {
      window.location.href = place;
    },
    mapcpmscarpark(carpark_id, carpark_name, venue_id) {
      window.open(
        "mapparkingcpms.html?carpark_id=" +
          carpark_id +
          "&carpark_name=" +
          carpark_name +
          "&venue_id=" +
          venue_id +
          "&brand_slug=" +
          this.brand_slug
      );
    },
    showaddbrand_action(brand_slug, venue_id) {
      this.carpark_id = "";
      this.carpark_name = "";
      this.carpark_identifier = "";
      this.total_parking_slots = "";
      this.entry_grace_hours = "";
      this.entry_grace_minutes = "";
      this.exit_grace_hours = "";
      this.exit_grace_minutes = "";
      this.visible_on_website = "";
      this.description = "";
      this.status = "";

      this.isDisabled = true;
      this.showaddbrand = true;
      this.showbrandgrid = false;
      this.showeditclient = false;
      this.showdatagrid = false;
      this.showdeleteclient = false;
    },

    editcarpark: function (carpark_id, venue_id) {
      this.isDisabled = true;
      this.showbrand = true;
      this.showvenue = true;
      this.brandDisabled = true;
      this.venueDisabled = true;
      this.showeditclient = true;
      this.showdatagrid = false;
      this.showdeleteclient = false;

      var err = 0;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var varToken = localStorage.getItem("x_token");
          var data =
            "carpark_id=" +
            carpark_id +
            "&brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&venue_id=" +
            venue_id +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id")) +
            "&userid=" +
            JSON.parse(localStorage.getItem("useridlogged"));

          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/single.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);

              if (this.codesent.user_exists == "Y") {
                this.carpark_id = this.codesent.data.carpark[0].carpark_id;
                this.carpark_name = this.codesent.data.carpark[0].carpark_name;
                this.carpark_identifier =
                  this.codesent.data.carpark[0].carpark_identifier;
                this.total_parking_slots =
                  this.codesent.data.carpark[0].total_parking_slots;
                this.visible_on_website =
                  this.codesent.data.carpark[0].visible_on_website;
                this.slot_duration_in_minutes =
                  this.codesent.data.carpark[0].slot_duration_in_minutes;
                this.description = this.codesent.data.carpark[0].description;
                this.status = this.codesent.data.carpark[0].status;

                let entry_grace_time =
                  this.codesent.data.carpark[0].entry_grace_time;
                let entry_grace_time_arr = entry_grace_time.replace(":");
                this.entry_grace_hours = this.fixzzero(entry_grace_time_arr[1]);
                this.entry_grace_minutes = this.fixzzero(
                  entry_grace_time_arr[0]
                );

                let exit_grace_time =
                  this.codesent.data.carpark[0].exit_grace_time;
                let exit_grace_time_arr = exit_grace_time.replace(":");
                this.exit_grace_hours = this.fixzzero(exit_grace_time_arr[1]);
                this.exit_grace_minutes = this.fixzzero(exit_grace_time_arr[0]);
                console.log("exit_grace_time", exit_grace_time);
                console.log("this.entry_grace_hours", this.entry_grace_hours);
                console.log(
                  "this.entry_grace_minutes",
                  this.entry_grace_minutes
                );
                console.log("this.exit_grace_hours", this.exit_grace_hours);
                console.log("this.exit_grace_minutes", this.exit_grace_minutes);
                console.log(
                  "this.codesent.data.carpark[0]",
                  this.codesent.data.carpark[0]
                );
              } else {
                this.$swal("Alert", this.codesent.data, "alert");
                //window.location.href="/index.html";
              }
            });
        }
      }
    },

    fixzzero(d) {
      if (d.length == 1) {
        d = "0" + d;
      }
      return d;
    },

    deletecarpark: function (carpark_id, venue_id) {
      this.isDisabled = true;
      this.showbrand = true;
      this.showvenue = true;
      this.brandDisabled = true;
      this.venueDisabled = true;
      this.showeditclient = false;
      this.showdatagrid = false;
      this.showdeleteclient = true;

      this.carpark_id = carpark_id;
      this.venue_id = venue_id;

      var err = 0;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var varToken = localStorage.getItem("x_token");
          var data =
            "carpark_id=" +
            carpark_id +
            "&brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&venue_id=" +
            venue_id +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id")) +
            "&userid=" +
            JSON.parse(localStorage.getItem("useridlogged"));

          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/single.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);

              if (this.codesent.user_exists == "Y") {
                this.carpark_id = this.codesent.data.carpark[0].carpark_id;
                this.carpark_name = this.codesent.data.carpark[0].carpark_name;
                this.carpark_identifier =
                  this.codesent.data.carpark[0].carpark_identifier;
                this.total_parking_slots =
                  this.codesent.data.carpark[0].total_parking_slots;
                this.slot_duration_in_minutes =
                  this.codesent.data.carpark[0].slot_duration_in_minutes;
                this.description = this.codesent.data.carpark[0].description;
                this.status = this.codesent.data.carpark[0].status;
                //alert(this.codesent.data.carpark[0].carpark_id);
              } else {
                this.$swal("Alert", this.codesent.data, "alert");
                //window.location.href="/index.html";
              }
            });
        }
      }
    },

    removecarpark: function () {
      var err = 0;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var data =
            "brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&venue_id=" +
            this.venue_id +
            "&carpark_id=" +
            this.carpark_id +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id")) +
            "&userid=" +
            JSON.parse(localStorage.getItem("useridlogged"));
          //alert(data);
          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/delete.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);
              if (this.codesent.user_exists == "Y") {
                this.$swal(
                  "Success",
                  "Car Parking Service deleted successfully",
                  "success"
                );
                //window.location.reload();

                this.isDisabled = false;
                this.showbrand = true;
                this.showvenue = true;
                this.brandDisabled = false;
                this.venueDisabled = false;
                this.showeditclient = false;
                this.showdatagrid = true;
                this.showdeleteclient = false;

                var data =
                  "brand_slug=" +
                  encodeURIComponent(this.brand_slug) +
                  "&venue_id=" +
                  this.venue_id +
                  "&brand_id=" +
                  JSON.parse(localStorage.getItem("brand_id"));
                //alert(data);
                axios
                  .post(
                    "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/allgrid.php",
                    data,
                    {
                      headers: {
                        Authorization: "Bearer " + varToken,
                        transhash: crypto
                          .createHash("sha256")
                          .update(window.location.hostname)
                          .digest("hex"),
                      },
                    }
                  )
                  .then((response) => {
                    this.codesent = JSON.parse(response.request.response);
                    //alert(this.codesent.user_exists);
                    if (this.codesent.user_exists == "Y") {
                      this.showdatagrid = true;
                      //this.isDisabled = false;
                      this.tableData = this.codesent.data;
                    } else {
                      this.$swal("Alert", this.codesent.data, "alert");
                      //window.location.href="/index.html";
                    }
                  });
              } else {
                this.$swal("Error", this.codesent.data, "error");
              }
            });
        }
      }
    },

    get_venue_by_brand: function () {
      var err = 0;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var data =
            "brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id"));
          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/all.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);
              //alert(this.codesent.user_exists);
              if (this.codesent.user_exists == "Y") {
                this.showvenue = true;
                this.venues = this.codesent.data;
                //window.location.reload();
              } else {
                this.$swal("Alert", this.codesent.data, "alert");
                //window.location.href="/index.html";
              }
            });
        }
      }
    },

    getcarpark: function () {
      var varToken = localStorage.getItem("x_token");
      if (varToken === null) {
        window.location.href = "/index.html";
      } else {
        this.isDisabled = false;
        this.showbrand = true;
        this.showvenue = true;
        this.brandDisabled = false;
        this.venueDisabled = false;
        this.showaddbrand = false;
        this.showeditclient = false;
        this.showdatagrid = true;
        this.showdeleteclient = false;

        var data =
          "brand_slug=" +
          encodeURIComponent(this.brand_slug) +
          "&venue_id=" +
          this.venue_id +
          "&brand_id=" +
          JSON.parse(localStorage.getItem("brand_id"));
        //alert(data);
        axios
          .post(
            "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/allgrid.php",
            data,
            {
              headers: {
                Authorization: "Bearer " + varToken,
                transhash: crypto
                  .createHash("sha256")
                  .update(window.location.hostname)
                  .digest("hex"),
              },
            }
          )
          .then((response) => {
            this.codesent = JSON.parse(response.request.response);
            //alert(this.codesent.user_exists);
            if (this.codesent.user_exists == "Y") {
              this.showdatagrid = true;
              //this.isDisabled = false;
              this.tableData = this.codesent.data;
            } else {
              this.$swal("Alert", this.codesent.data, "alert");
              //window.location.href="/index.html";
            }
          });
      }
    },

    createcarpark: function () {
      var err = 0;

      if (this.carpark_name == "") {
        this.carpark_name_hasError = true;
        err++;
      }
      if (this.carpark_identifier == "") {
        this.carpark_identifier_hasError = true;
        err++;
      }
      if (this.total_parking_slots == "") {
        this.total_parking_slots_hasError = true;
        err++;
      }
      if (this.visible_on_website == "") {
        this.visible_on_website_hasError = true;
        err++;
      }
      if (this.slot_duration_in_minutes == "") {
        this.slot_duration_in_minutes_hasError = true;
        err++;
      }
      if (this.description == "") {
        this.description_hasError = true;
        err++;
      }
      if (this.status == "") {
        this.status_hasError = true;
        err++;
      }
      let entry_grace_time =
        this.entry_grace_hours + " " + this.entry_grace_minutes;
      let exit_grace_time =
        this.exit_grace_hours + " " + this.exit_grace_minutes;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var data =
            "brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&venue_id=" +
            this.venue_id +
            "&carpark_name=" +
            encodeURIComponent(this.carpark_name) +
            "&carpark_identifier=" +
            encodeURIComponent(this.carpark_identifier) +
            "&total_parking_slots=" +
            encodeURIComponent(this.total_parking_slots) +
            "&visible_on_website=" +
            encodeURIComponent(this.visible_on_website) +
            "&slot_duration_in_minutes=" +
            encodeURIComponent(this.slot_duration_in_minutes) +
            "&entry_grace_time=" +
            encodeURIComponent(entry_grace_time) +
            "&exit_grace_time=" +
            encodeURIComponent(exit_grace_time) +
            "&description=" +
            encodeURIComponent(this.description) +
            "&status=" +
            encodeURIComponent(this.status) +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id")) +
            "&userid=" +
            JSON.parse(localStorage.getItem("useridlogged"));
          //alert(data);
          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/add.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);
              if (this.codesent.user_exists == "Y") {
                this.$swal(
                  "Success",
                  "Car Parking Service added successfully",
                  "success"
                );
                //window.location.reload();

                this.isDisabled = false;
                this.showbrand = true;
                this.showvenue = true;
                this.brandDisabled = false;
                this.venueDisabled = false;
                this.showaddbrand = false;
                this.showeditclient = false;
                this.showdatagrid = true;
                this.showdeleteclient = false;

                var data =
                  "brand_slug=" +
                  encodeURIComponent(this.brand_slug) +
                  "&venue_id=" +
                  this.venue_id +
                  "&brand_id=" +
                  JSON.parse(localStorage.getItem("brand_id"));
                //alert(data);
                axios
                  .post(
                    "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/allgrid.php",
                    data,
                    {
                      headers: {
                        Authorization: "Bearer " + varToken,
                        transhash: crypto
                          .createHash("sha256")
                          .update(window.location.hostname)
                          .digest("hex"),
                      },
                    }
                  )
                  .then((response) => {
                    this.codesent = JSON.parse(response.request.response);
                    //alert(this.codesent.user_exists);
                    if (this.codesent.user_exists == "Y") {
                      this.showdatagrid = true;
                      //this.isDisabled = false;
                      this.tableData = this.codesent.data;
                    } else {
                      this.$swal("Alert", this.codesent.data, "alert");
                      //window.location.href="/index.html";
                    }
                  });
              } else {
                this.$swal("Error", this.codesent.data, "error");
              }
            });
        }
      }
    },

    carparkdetails(id, name) {
      this.carparkdetails_id = id;
      this.carparkdetails_name = name;
      var varToken = localStorage.getItem("x_token");
      var data = {
        brandslug: this.brandid,
        carpark_id: this.carparkdetails_id,
      };
      axios
        .post(
          "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/updatecarparkdetailssingle.php",
          data,
          {
            headers: {
              Authorization: "Bearer " + varToken,
              transhash: crypto
                .createHash("sha256")
                .update(window.location.hostname)
                .digest("hex"),
            },
          }
        )
        .then((response) => {
          this.codesent = JSON.parse(response.request.response);
          if (this.codesent.user_exists == "Y") {
            this.carparkdetails_modal = true;
            let cl = this.codesent.clients[0];
            this.feature_1 = cl.feature_1;
            this.feature_2 = cl.feature_2;
            this.feature_3 = cl.feature_3;
            this.feature_4 = cl.feature_4;
            this.feature_5 = cl.feature_5;
            this.distance_from_terminal_1 = cl.distance_from_terminal_1;
            this.distance_from_terminal_2 = cl.distance_from_terminal_2;
            this.distance_from_terminal_3 = cl.distance_from_terminal_3;
            this.distance_from_terminal_4 = cl.distance_from_terminal_4;
            this.distance_from_terminal_5 = cl.distance_from_terminal_5;
            this.distance_from_terminal_6 = cl.distance_from_terminal_6;
            this.distance_unit_1 = cl.distance_unit_1;
            this.distance_unit_2 = cl.distance_unit_2;
            this.distance_unit_3 = cl.distance_unit_3;
            this.distance_unit_4 = cl.distance_unit_4;
            this.distance_unit_5 = cl.distance_unit_5;
            this.distance_unit_6 = cl.distance_unit_6;
            this.distance_in_time_1 = cl.distance_in_time_1;
            this.distance_in_time_2 = cl.distance_in_time_2;
            this.distance_in_time_3 = cl.distance_in_time_3;
            this.distance_in_time_4 = cl.distance_in_time_4;
            this.distance_in_time_5 = cl.distance_in_time_5;
            this.distance_in_time_6 = cl.distance_in_time_6;
            this.grid_image_exist = cl.grid_image;
            this.list_image_exist = cl.list_image;
            this.map_pdf_exist = cl.map_pdf;
          }
        })
        .catch(function () {
          this.$swal("Error", "FAILURE", "error");
        });
    },
    update_carparkdetails() {
      let carparkdetails_form = document.getElementById("carparkdetails_form");
      let formData = new FormData(carparkdetails_form);
      formData.append("brandslug", this.brand_slug);
      formData.append("carpark_id", this.carparkdetails_id);
      formData.append("feature_1", this.feature_1);
      formData.append("feature_2", this.feature_2);
      formData.append("feature_3", this.feature_3);
      formData.append("feature_4", this.feature_4);
      formData.append("feature_5", this.feature_5);

      formData.append(
        "distance_from_terminal_1",
        this.distance_from_terminal_1
      );
      formData.append(
        "distance_from_terminal_2",
        this.distance_from_terminal_2
      );
      formData.append(
        "distance_from_terminal_3",
        this.distance_from_terminal_3
      );
      formData.append(
        "distance_from_terminal_4",
        this.distance_from_terminal_4
      );
      formData.append(
        "distance_from_terminal_5",
        this.distance_from_terminal_5
      );
      formData.append(
        "distance_from_terminal_6",
        this.distance_from_terminal_6
      );

      formData.append("distance_unit_1", this.distance_unit_1);
      formData.append("distance_unit_2", this.distance_unit_2);
      formData.append("distance_unit_3", this.distance_unit_3);
      formData.append("distance_unit_4", this.distance_unit_4);
      formData.append("distance_unit_5", this.distance_unit_5);
      formData.append("distance_unit_6", this.distance_unit_6);

      formData.append("distance_in_time_1", this.distance_in_time_1);
      formData.append("distance_in_time_2", this.distance_in_time_2);
      formData.append("distance_in_time_3", this.distance_in_time_3);
      formData.append("distance_in_time_4", this.distance_in_time_4);
      formData.append("distance_in_time_5", this.distance_in_time_5);
      formData.append("distance_in_time_6", this.distance_in_time_6);
      formData.append("grid_image", this.grid_image);
      formData.append("list_image", this.list_image);
      formData.append("map_pdf", this.map_pdf);

      var varToken = localStorage.getItem("x_token");
      if (varToken === null) {
        window.location.href = "/index.html";
      } else {
        axios
          .post(
            "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/updatecarparkdetails.php",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + varToken,
                transhash: crypto
                  .createHash("sha256")
                  .update(window.location.hostname)
                  .digest("hex"),
              },
            }
          )
          .then((response) => {
            this.codesent = JSON.parse(response.request.response);
            if (this.codesent.user_exists == "Y") {
              this.$swal(
                "Success",
                "Car park settings saved successfully",
                "success"
              );
            } else {
              this.$swal(
                "Error",
                "Car park settings could not be saved.",
                "error"
              );
            }
          })
          .catch(function () {
            this.$swal("Error", "FAILURE", "error");
          });
      }
    },
    updatecarpark: function () {
      var err = 0;

      if (this.carpark_name == "") {
        this.carpark_name_hasError = true;
        err++;
      }
      if (this.carpark_identifier == "") {
        this.carpark_identifier_hasError = true;
        err++;
      }
      if (this.total_parking_slots == "") {
        this.total_parking_slots_hasError = true;
        err++;
      }
      if (this.visible_on_website == "") {
        this.visible_on_website_hasError = true;
        err++;
      }
      if (this.slot_duration_in_minutes == "") {
        this.slot_duration_in_minutes_hasError = true;
        err++;
      }
      if (this.description == "") {
        this.description_hasError = true;
        err++;
      }
      if (this.status == "") {
        this.status_hasError = true;
        err++;
      }
      let entry_grace_time =
        this.entry_grace_hours + " " + this.entry_grace_minutes;
      let exit_grace_time =
        this.exit_grace_hours + " " + this.exit_grace_minutes;

      if (err == 0) {
        var varToken = localStorage.getItem("x_token");
        if (varToken === null) {
          window.location.href = "/index.html";
        } else {
          var data =
            "brand_slug=" +
            encodeURIComponent(this.brand_slug) +
            "&venue_id=" +
            this.venue_id +
            "&carpark_name=" +
            encodeURIComponent(this.carpark_name) +
            "&carpark_identifier=" +
            encodeURIComponent(this.carpark_identifier) +
            "&total_parking_slots=" +
            encodeURIComponent(this.total_parking_slots) +
            "&visible_on_website=" +
            encodeURIComponent(this.visible_on_website) +
            "&slot_duration_in_minutes=" +
            encodeURIComponent(this.slot_duration_in_minutes) +
            "&entry_grace_time=" +
            encodeURIComponent(entry_grace_time) +
            "&exit_grace_time=" +
            encodeURIComponent(exit_grace_time) +
            "&description=" +
            encodeURIComponent(this.description) +
            "&status=" +
            encodeURIComponent(this.status) +
            "&carpark_id=" +
            this.carpark_id +
            "&brand_id=" +
            JSON.parse(localStorage.getItem("brand_id")) +
            "&userid=" +
            JSON.parse(localStorage.getItem("useridlogged"));
          //alert(data);
          axios
            .post(
              "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/update.php",
              data,
              {
                headers: {
                  Authorization: "Bearer " + varToken,
                  transhash: crypto
                    .createHash("sha256")
                    .update(window.location.hostname)
                    .digest("hex"),
                },
              }
            )
            .then((response) => {
              this.codesent = JSON.parse(response.request.response);
              if (this.codesent.user_exists == "Y") {
                this.$swal(
                  "Success",
                  "Car Parking Service updated successfully",
                  "success"
                );
                //window.location.reload();

                this.isDisabled = false;
                this.showbrand = true;
                this.showvenue = true;
                this.brandDisabled = false;
                this.venueDisabled = false;
                this.showeditclient = false;
                this.showdatagrid = true;
                this.showdeleteclient = false;

                var data =
                  "brand_slug=" +
                  encodeURIComponent(this.brand_slug) +
                  "&venue_id=" +
                  this.venue_id +
                  "&brand_id=" +
                  JSON.parse(localStorage.getItem("brand_id"));
                //alert(data);
                axios
                  .post(
                    "https://devhub.rezcomm.com/hubapmydbauth/carparkingservice/allgrid.php",
                    data,
                    {
                      headers: {
                        Authorization: "Bearer " + varToken,
                        transhash: crypto
                          .createHash("sha256")
                          .update(window.location.hostname)
                          .digest("hex"),
                      },
                    }
                  )
                  .then((response) => {
                    this.codesent = JSON.parse(response.request.response);
                    //alert(this.codesent.user_exists);
                    if (this.codesent.user_exists == "Y") {
                      this.showdatagrid = true;
                      //this.isDisabled = false;
                      this.tableData = this.codesent.data;
                    } else {
                      this.$swal("Alert", this.codesent.data, "alert");
                      //window.location.href="/index.html";
                    }
                  });
              } else {
                this.$swal("Error", this.codesent.data, "error");
              }
            });
        }
      }
    },
  },
  data: () => ({
    baseclasses: "mb-1 px-1 menu-button",
    timeinhours: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    timeminutes: [
      "00",
      "05",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
      "55",
      "59",
    ],
    showdatagrid: false,
    carparkdetails_modal: false,
    brandid: "",
    showaddbrand: false,
    showbrandgrid: false,
    showeditclient: false,
    showdeleteclient: false,

    showbrand: true,
    showvenue: false,

    isDisabled: true,
    brandDisabled: false,
    venueDisabled: false,

    brand_id: "",
    brands: [],
    distance_unit_options: ["meters", "miles", "km"],
    brand_slug: "",
    brands_hasError: false,

    venue_id: "",
    venue_name: "",
    venues: [],
    venue_name_hasError: false,

    carpark_id: "",

    carpark_name: "",
    carpark_name_hasError: false,

    carpark_identifier: "",
    carpark_identifier_hasError: false,

    total_parking_slots: "",
    total_parking_slots_hasError: false,

    slot_duration_in_minutes: "",
    slot_duration_in_minutes_options: [
      { name: "15" },
      { name: "30" },
      { name: "45" },
      { name: "60" },
      { name: "120" },
      { name: "180" },
      { name: "240" },
      { name: "300" },
      { name: "360" },
      { name: "420" },
      { name: "480" },
    ],
    slot_duration_in_minutes_hasError: false,

    description: "",
    description_hasError: false,

    status: "",
    status_hasError: false,

    carparkdetails_id: "",
    carparkdetails_name: "",
    feature_1: "",
    feature_2: "",
    feature_3: "",
    feature_4: "",
    feature_5: "",
    distance_from_terminal_1: "",
    distance_from_terminal_2: "",
    distance_from_terminal_3: "",
    distance_from_terminal_4: "",
    distance_from_terminal_5: "",
    distance_from_terminal_6: "",
    distance_unit_1: "",
    distance_unit_2: "",
    distance_unit_3: "",
    distance_unit_4: "",
    distance_unit_5: "",
    distance_unit_6: "",
    distance_in_time_1: "",
    distance_in_time_2: "",
    distance_in_time_3: "",
    distance_in_time_4: "",
    distance_in_time_5: "",
    distance_in_time_6: "",
    grid_image: [],
    list_image: [],
    map_pdf: [],
    grid_image_exist: "",
    list_image_exist: "",
    map_pdf_exist: "",
    carparkimages: [],
    visible_on_website: "",
    entry_grace_hours: "",
    entry_grace_minutes: "",
    exit_grace_hours: "",
    exit_grace_minutes: "",
    visible_on_website_hasError: false,
    entry_grace_hours_hasError: false,
    entry_grace_minutes_hasError: false,
    exit_grace_hours_hasError: false,
    exit_grace_minutes_hasError: false,
    status_options: [
      { name: "Active", code: "A" },
      { name: "Inactive", code: "I" },
    ],
    visible_on_website_options: [
      { name: "Yes", code: "Y" },
      { name: "No", code: "N" },
    ],

    openedpanel: [],
    childpanel: [],
    columns: [
      "carpark_name",
      "carpark_identifier",
      "total_parking_slots",
      "status",
      "actions",
    ],
    tableData: [],
    options: {
      perPage: 25,
      filterByColumn: true,
      filterable: ["carpark_name", "carpark_identifier", "status"],
      perPageValues: [25, 50, 100, 200],
      params: { brand_id: JSON.parse(localStorage.getItem("brand_id")) },
    },

    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
  }),
  created() {},

  mounted() {
    var varToken = localStorage.getItem("x_token");
    if (varToken === null) {
      window.location.href = "/index.html";
    } else {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("name")) {
        this.carpark_name = urlParams.get("name");
      }
      if (urlParams.has("id")) {
        this.carpark_id = urlParams.get("id");
      }
      if (urlParams.has("brand")) {
        this.brandid = urlParams.get("brand");
      }
      this.carparkdetails(this.carpark_id, this.carpark_name);
    }
  },
};
</script>
