<template>
  <ul class="menu-nav">
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Single Administration</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vue-bootstrap/card"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Single Admin Sub 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vue-bootstrap/carousel"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Single Admin Sub 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vue-bootstrap/collapse"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Single Admin Sub 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        ref="customer_information_management"
      >
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Customer Information Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/M6toll.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Customers</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/M6tollTransactions.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Transactions</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="loggeduser_groupid != 9"
            >
              <a href="/globalusersearch.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Global User Search</span>
              </a>
            </li>
          </router-link>
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/unblockuser.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Unblock User</span>
              </a>
            </li>
          </router-link>

          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="customer_information_management_gdpr"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">GDPR</span>
              <i class="menu-arrow"></i>
            </a>

            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                    v-if="loggeduser_groupid != 10 && loggeduser_groupid != 12"
                  >
                    <a href="/manualanon.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Manual Anonymization</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/anonlogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Anonymization Logs</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 11
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="marketing_analytics">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Analytics</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="marketing_analytics_email_logs"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Email Logs</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                    v-if="loggeduser_groupid != 10 && loggeduser_groupid != 12"
                  >
                    <a href="/sesdashboard.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">SES Dashboard</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/analytics.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Email Campaign Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="loggeduser_groupid != 10 && loggeduser_groupid != 12"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/sesanalytics.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Transactional Email Logs</span>
                    </a>
                  </li>
                </router-link>

                <!------ <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }" v-if="loggeduser_groupid!=10"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a href="/transactioncomplaints.html" class="menu-link" >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Transactional - Complaints</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }" v-if="loggeduser_groupid!=10"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a href="/transactionbounces.html" class="menu-link" >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Transactional - Bounces</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }" v-if="loggeduser_groupid!=10"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a href="/campaigncomplaints.html" class="menu-link" >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Campaign - Complaints</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }" v-if="loggeduser_groupid!=10"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a href="/campaignbounces.html" class="menu-link" >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Campaign - Bounces</span>
                    </a>
                  </li>
                </router-link>------>
              </ul>
            </div>
          </li>

          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
              'menu-item-open': hasActiveChildren('/vue-bootstrap/form'),
            }"
            v-if="
              loggeduser_groupid != 10 &&
              loggeduser_groupid != 11 &&
              loggeduser_groupid != 12
            "
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="marketing_analytics_audit_logs"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Audit Logs</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/koweelogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text"> Kowee Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/refundlogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Refund Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="javascript:;" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">SMS Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/wsdllogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">CPMS Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/peellogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Ipark FTP Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/auditlogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Shop General</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="loggeduser_groupid != 10"
    >
      <a href="#" class="menu-link menu-toggle" ref="business_bi">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">BI</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="business_bi_operations"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Operations (PII)</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopfulfillreport.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Manage Bookings</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 14 &&
                    loggeduser_groupid != 13 &&
                    (loggeduser_brandid == 'NWI' ||
                      loggeduser_brandid == 'HER' ||
                      loggeduser_brandid == 'NA')
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/historicbookings.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Historic Bookings</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 14 &&
                    loggeduser_groupid != 13 &&
                    (loggeduser_brandid == 'NWI' ||
                      loggeduser_brandid == 'NA' ||
                      loggeduser_brandid == 'HER')
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/parkingbookings.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 10
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Bookings</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/Paymentlogs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">SIBS Payment Logs</span>
                    </a>
                  </li>
                </router-link>                
				
				<router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/downloadreports.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Download Reports</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/Weologs.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Weo Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 14 &&
                    loggeduser_groupid != 13 &&
                    (loggeduser_brandid == 'NWI' ||
                      loggeduser_brandid == 'NA' ||
                      loggeduser_brandid == 'HER')
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/logscpms.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 10
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">CPMS Logs</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/m6mepuat.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">MEP Orders</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/m6utr.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Payment Notices</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/m6tagsuat.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">I Buy Orders</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/m6contactuat.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Contact Form Submissions</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/M6tagsregnoupdate.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Tag Reg Change Requests</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/M6tagsreplace.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Tag Replace Requests</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/M6tagsreqnew.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 13 &&
                        loggeduser_groupid != 14 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Tag Bracket Requests</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 13
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="reporting_forecasting">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Reporting & Forecasting (Non PII)</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="reporting_forecasting_commercials"
              v-if="
                loggeduser_groupid != 8 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 12 &&
                loggeduser_groupid != 7
              "
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Travel Log</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/shopnoresultlog.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">No Result Log</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
          >
            <a
              href="#"
              class="menu-link menu-toggle"
              ref="reporting_forecasting_commercials"
              v-if="loggeduser_groupid != 9"
            >
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Commercial</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    (loggeduser_brandid == 'NWI' ||
                      loggeduser_brandid == 'NA' ||
                      loggeduser_brandid == 'HER')
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/parkingoccupancyreport.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Parking Occupancy Report</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    (loggeduser_brandid == 'NWI' ||
                      loggeduser_brandid == 'NA' ||
                      loggeduser_brandid == 'HER')
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/parkingoccupancybirdseyeview.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text"
                        >Parking Occupancy Birds Eye View</span
                      >
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 12 &&
                    loggeduser_groupid != 11
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/shopcommreport.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Commercial Report</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="
                    loggeduser_groupid != 7 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 8 &&
                    loggeduser_groupid != 10 &&
                    loggeduser_groupid != 12 &&
                    loggeduser_groupid != 11
                  "
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a href="/shopcommreportv6.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Commercial Report V6</span>
                    </a>
                  </li>
                </router-link>

                <!-- <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                    v-if="
                      loggeduser_groupid != 7 &&
                      loggeduser_groupid != 10 &&
                      loggeduser_groupid != 8 &&
                      loggeduser_groupid != 10 &&
                      loggeduser_groupid != 12 &&
                      loggeduser_groupid != 11
                    "
                  >
                    <a href="/shopcommsnapshot.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Commercial Snapshot</span>
                    </a>
                  </li>
                </router-link> -->

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                    v-if="
                      loggeduser_groupid != 8 &&
                      loggeduser_groupid != 9 &&
                      loggeduser_groupid != 88 &&
                      loggeduser_groupid != 10 &&
                      (loggeduser_brandid == 'NWI' ||
                        loggeduser_brandid == 'NA')
                    "
                  >
                    <a href="/shopcommsnapshotv6.html" class="menu-link">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Commercial Snapshot</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopcommentryparking.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Entry Parking</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopcommdstayoverview.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Stay Overview</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopv5commsalesperf.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Sales Performance</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopv5commcancelperf.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Cancellation Report</span>
                    </a>
                  </li>
                </router-link>

                <router-link
                  to=""
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      href="/shopv5commlatency.html"
                      class="menu-link"
                      v-if="
                        loggeduser_groupid != 8 &&
                        loggeduser_groupid != 9 &&
                        loggeduser_groupid != 10 &&
                        loggeduser_groupid != 11 &&
                        loggeduser_groupid != 7 &&
                        loggeduser_groupid != 12
                      "
                    >
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">Trends</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Yield Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="business_languages">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Languages</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/languages.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Languages</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/translations.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Translations</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        ref="marketing_analytics_access"
      >
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Access</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/users.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Users</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/usergroups.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">User Groups</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/permissions.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">User Permissions</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/apiusers.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">API Users</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/apiurls.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">API URL(s)</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        ref="marketing_analytics_clients"
      >
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Clients</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/clients.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Clients</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/brands.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Brands</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        ref="marketing_analytics_clients"
      >
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Contract Wiki</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/contracts.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Contract Wiki</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="business_partners">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">Partners</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/partnersManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Partner Management</span>
              </a>
            </li>
          </router-link>
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/partnersBrandMapping.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Brand Product Mapping</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 13 &&
        loggeduser_groupid != 14 &&
        loggeduser_groupid != 12
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="flowy_ui">
        <i class="menu-icon flaticon-web"></i>
        <span class="menu-text">FlowyUI</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/flowyUI.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">FlowyUI</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "KTBusinessMenu",
  props: ["showactiverezmenu", "showactiverezsubmenu"],
  methods: {
    hasActiveChildren(match) {
      return false;
    },
  },
  data() {
    return {
      loggeduser_groupid: 0,
      loggeduser_brandid: 0,
    };
  },
  mounted() {
    //console.log('showactiverezmenu',this.showactiverezmenu);
    //console.log('showactiverezsubmenu',this.showactiverezsubmenu);
    this.$nextTick(() => {
      if (typeof this.showactiverezsubmenu !== "undefined") {
        setTimeout(() => {
          if (this.$refs[this.showactiverezsubmenu]) {
            this.$refs[this.showactiverezsubmenu].click();
          }
        }, 1000);
      }
      if (typeof this.showactiverezmenu !== "undefined") {
        setTimeout(() => {
          if (this.$refs[this.showactiverezmenu]) {
            this.$refs[this.showactiverezmenu].click();
          }
        }, 2000);
      }
    });
    //this.$refs.kt_marketing_analytics.click();

    let grpid = localStorage.getItem("group_id");
    grpid = grpid.replace('"', "");
    this.loggeduser_groupid = grpid.replace('"', "");
    let brndid = localStorage.getItem("brand_id");
    brndid = brndid.replace('"', "");
    this.loggeduser_brandid = brndid.replace('"', "");
  },
};
</script>
