import { fetchCampaignStatus, fetchCampaignSummary } from '../services';

// action types
export const FETCH_CAMPAIGN_STATUS_DATA = "fetchCampaignStatusData";
export const FETCH_CAMPAIGN_SUMMARY_DATA = "fetchCampaignSummaryData";

// mutation types
export const CAMPAIGN_DATA_REQUEST = "setCampaignDataRequest";
export const CAMPAIGN_STATUS_DATA_SUCCESS = "setCampaignStatusDataSuccess";
export const CAMPAIGN_SUMMARY_DATA_SUCCESS = "setCampaignSummaryDataSuccess";
export const CAMPAIGN_DATA_ERROR = "setCampaignDataError";
export const CAMPAIGN_DATA_RESET = "setCampaignDataReset";

/** Initial state */
const state = {
    loading: false,
    campaign_status: null,
    campaign_summary: null,
    error: null
};

const getters = {
    campaignStatus(state) {
        return state.campaign_status;
    },
    campaignSummary(state) {
        return state.campaign_summary;
    },
}

/**
 * Campaigns data mutations
 */
const mutations = {
    /** campaign data request */
    [CAMPAIGN_DATA_REQUEST](state) {
        Object.assign(state, { loading: true, error: null });
    },

    /** campaign status data success */
    [CAMPAIGN_STATUS_DATA_SUCCESS](state, payload) {
        Object.assign(state, { loading: false, campaign_status: payload });
    },

    /** campaign summary data success */
    [CAMPAIGN_SUMMARY_DATA_SUCCESS](state, payload) {
        Object.assign(state, { loading: false, campaign_summary: payload });
    },

    /** campaign data error */
    [CAMPAIGN_DATA_ERROR](state, payload) {
        Object.assign(state, {
            loading: false,
            campaign_status: null,
            campaign_summary: null,
            error: payload || true
        });
    },

    /** reset campaign data */
    [CAMPAIGN_DATA_RESET](state) {
        Object.assign(state, ...state);
    }
};

/**
 * Campaign data actions
 */
const actions = {
    /** fetch campaign status data */
    async [FETCH_CAMPAIGN_STATUS_DATA](context, payload) {
        context.commit(CAMPAIGN_DATA_REQUEST, payload);

        const result = await fetchCampaignStatus(payload).catch(e => {
            context.commit(CAMPAIGN_DATA_ERROR, e);
        });

        if (result) {
            context.commit(CAMPAIGN_STATUS_DATA_SUCCESS, result);
        }

        return result;
    },
    /** fetch campaign summary data */
    async [FETCH_CAMPAIGN_SUMMARY_DATA](context, payload) {
        context.commit(CAMPAIGN_DATA_REQUEST, payload);

        const result = await fetchCampaignSummary(payload).catch(e => {
            context.commit(CAMPAIGN_DATA_ERROR, e);
        });

        if (result) {
            context.commit(CAMPAIGN_SUMMARY_DATA_SUCCESS, result);
        }

        return result;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};