var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('v-container',{staticStyle:{"margin":"0px","padding":"0px","width":"100%","min-height":"100%","display":"block"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-container',{staticStyle:{"margin":"0px","padding":"0px","width":"100%","min-height":"100%","display":"block"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('div',{staticClass:"container3",staticStyle:{"height":"100%"}},[_c('div',{attrs:{"id":"people"}},[_c('v-layout',{attrs:{"text-right":"","wrap":""}}),_c('v-layout',{attrs:{"wrap":""}},[_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"100%"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.showtrans("Car Park Details"))+" - "+_vm._s(_vm.carparkdetails_name)+" ")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-6"},[_c('v-form',{ref:"carparkdetails_form",attrs:{"id":"carparkdetails_form"}},[_c('div',{staticClass:"kit-status"},[_c('v-row',{staticClass:"pb-8"},[_c('v-col',{staticClass:"py-0",attrs:{"col":"3"}},[_c('v-file-input',{attrs:{"chips":"","label":"Grid Image","rules":_vm.rules,"accept":"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.grid_image),callback:function ($$v) {_vm.grid_image=$$v},expression:"grid_image"}}),_c('div',[_c('div',[_vm._v(" Existing Grid Image: "),_c('a',{attrs:{"href":_vm.grid_image_exist,"target":"_blank"}},[_vm._v("View")])]),_c('img',{staticStyle:{"margin-top":"1rem"},attrs:{"src":_vm.grid_image_exist,"alt":"","width":"200px","height":"auto"}})])],1),_c('v-col',{staticClass:"py-0",attrs:{"col":"3"}},[_c('v-file-input',{attrs:{"chips":"","label":"List Image","rules":_vm.rules,"accept":"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.list_image),callback:function ($$v) {_vm.list_image=$$v},expression:"list_image"}}),_c('div',[_c('div',[_vm._v(" Existing List Image: "),_c('a',{attrs:{"href":_vm.list_image_exist,"target":"_blank"}},[_vm._v("View")])]),_c('img',{staticStyle:{"margin-top":"1rem"},attrs:{"src":_vm.list_image_exist,"alt":"","width":"200px","height":"auto"}})])],1),_c('v-col',{staticClass:"py-0",attrs:{"col":"3"}},[_c('v-file-input',{attrs:{"chips":"","label":"Map Image","rules":_vm.rules,"accept":"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.map_pdf),callback:function ($$v) {_vm.map_pdf=$$v},expression:"map_pdf"}}),_c('div',[_c('div',[_vm._v(" Existing Map Image: "),_c('a',{attrs:{"href":_vm.map_pdf_exist,"target":"_blank"}},[_vm._v("View")])]),_c('img',{staticStyle:{"margin-top":"1rem"},attrs:{"src":_vm.map_pdf_exist,"alt":"","width":"200px","height":"auto"}})])],1),_c('v-col',{staticClass:"py-0",attrs:{"col":"3"}},[_c('v-file-input',{attrs:{"chips":"","label":"More Info Image","rules":_vm.rules,"accept":"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.map_pdf),callback:function ($$v) {_vm.map_pdf=$$v},expression:"map_pdf"}}),_c('div',[_c('div',[_vm._v(" Existing More Info Image: "),_c('a',{attrs:{"href":_vm.map_pdf_exist,"target":"_blank"}},[_vm._v("View")])]),_c('img',{staticStyle:{"margin-top":"1rem"},attrs:{"src":_vm.map_pdf_exist,"alt":"","width":"200px","height":"auto"}})])],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('v-textarea',{attrs:{"outlined":"","name":"feature_1","label":"Feature List 1","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.feature_1),callback:function ($$v) {_vm.feature_1=$$v},expression:"feature_1"}})],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-textarea',{attrs:{"outlined":"","name":"feature_2","label":"Feature List 2","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.feature_2),callback:function ($$v) {_vm.feature_2=$$v},expression:"feature_2"}})],1),_c('v-col',{attrs:{"col":"4"}},[_c('v-textarea',{attrs:{"outlined":"","name":"feature_3","label":"Feature List 3","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.feature_3),callback:function ($$v) {_vm.feature_3=$$v},expression:"feature_3"}}),_c('v-textarea',{staticStyle:{"display":"none"},attrs:{"outlined":"","name":"feature_5","label":"Feature List 5 not used","rows":"5"},model:{value:(_vm.feature_5),callback:function ($$v) {_vm.feature_5=$$v},expression:"feature_5"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"col":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Tag Line","name":"feature_4","maxlength":"150"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.feature_4),callback:function ($$v) {_vm.feature_4=$$v},expression:"feature_4"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"col":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Distance in time from Terminal","name":"distance_in_time_1","maxlength":"150"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis repellendus dolore illum commodi vel error adipisci id omnis fuga nisi est, laborum voluptatibus qui eaque corrupti dicta, tempore rem quae! ")])])]},proxy:true}]),model:{value:(_vm.distance_in_time_1),callback:function ($$v) {_vm.distance_in_time_1=$$v},expression:"distance_in_time_1"}})],1)],1)],1)])],1),_c('v-divider',{staticClass:"m-0"}),_c('v-card-actions',{staticClass:"grey lighten-5"},[_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.update_carparkdetails}},[_vm._v(" Update ")]),_c('v-btn',{staticClass:"ml-2 lighten-2",attrs:{"color":"grey","outlined":"","depressed":""},on:{"click":function($event){return _vm.gotoUrl('/carparkingservice.html')}}},[_vm._v(" Back ")])],1)],1)],1)],1),(_vm.showdatagrid)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-client-table',{ref:"vuetable",staticClass:"v-data-table theme--light",attrs:{"data":_vm.tableData,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('button',{on:{"click":function($event){return _vm.editcarpark(row.carpark_id, row.venue_id)}}},[_c('v-icon',[_vm._v("mdi-pencil-box")])],1),_c('button',{attrs:{"title":"Carpark Details"},on:{"click":function($event){return _vm.carparkdetails(row.carpark_id, row.carpark_name)}}},[_c('v-icon',[_vm._v("mdi-details")])],1),_c('button',{on:{"click":function($event){return _vm.deletecarpark(row.carpark_id, row.venue_id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)])}}],null,false,329693795)})],1):_vm._e(),(_vm.showaddbrand)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-content',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.showtrans("Add Car Parking Service")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-text-field',{class:{
                                textdanger: _vm.carpark_name_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Name'),"name":"carpark_name","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Name - The name of the car park. This field is mandatory. ")])])]},proxy:true}],null,false,4037389518),model:{value:(_vm.carpark_name),callback:function ($$v) {_vm.carpark_name=$$v},expression:"carpark_name"}}),_c('v-text-field',{class:{
                                textdanger: _vm.carpark_identifier_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Identifier'),"name":"carpark_identifier","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Identifier - The identifier of the carpark in most cases 3 or 4 digits. If this is an existing client then best to check if they already use an identifier for the car park in question and then use the same identifier. If they don't have one then please make up sensible identifiers. For example if they have a car park called Car Park 1 - perhaps CP1 is the simplest identifier as opposed to YU098MG847. Stick to the KISS principle when in doubt but it still in doubt ask the Rezcomm Team. This field is mandatory ")])])]},proxy:true}],null,false,888331308),model:{value:(_vm.carpark_identifier),callback:function ($$v) {_vm.carpark_identifier=$$v},expression:"carpark_identifier"}}),_c('v-text-field',{class:{
                                textdanger: _vm.total_parking_slots_hasError,
                              },attrs:{"label":_vm.showtrans('Total Parking Slots'),"name":"total_parking_slots","maxlength":"10","type":"number","min":"0"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" The number of parking slots at the car park. This field is mandatory and must be greater than 0. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2834967084),model:{value:(_vm.total_parking_slots),callback:function ($$v) {_vm.total_parking_slots=$$v},expression:"total_parking_slots"}}),_c('v-text-field',{class:{
                                textdanger: _vm.slot_duration_in_minutes_hasError,
                              },attrs:{"label":_vm.showtrans('Slot Duration in minutes'),"name":"slot_duration_in_minutes","maxlength":"10","type":"number","min":"0"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Slot duration in minutes - This is the minimum duration one can book parking for. Your options are 15/30/45/60 minutes. This is a mandatory field. Once this has been set this field cannot be changed or edited. This is a mandatory field. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2004837643),model:{value:(_vm.slot_duration_in_minutes),callback:function ($$v) {_vm.slot_duration_in_minutes=$$v},expression:"slot_duration_in_minutes"}}),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Entry Grace Time - Hours'),"name":"entry_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of entry for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,236656442),model:{value:(_vm.entry_grace_hours),callback:function ($$v) {_vm.entry_grace_hours=$$v},expression:"entry_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Entry Grace Time - Minutes'),"name":"entry_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual booked time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,1510013468),model:{value:(_vm.entry_grace_minutes),callback:function ($$v) {_vm.entry_grace_minutes=$$v},expression:"entry_grace_minutes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Exit Grace Time - Hours'),"name":"exit_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of exit for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2259308954),model:{value:(_vm.exit_grace_hours),callback:function ($$v) {_vm.exit_grace_hours=$$v},expression:"exit_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Exit Grace Time - Minutes'),"name":"exit_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2738900032),model:{value:(_vm.exit_grace_minutes),callback:function ($$v) {_vm.exit_grace_minutes=$$v},expression:"exit_grace_minutes"}})],1)],1),_c('v-select',{class:{
                                textdanger: _vm.visible_on_website_hasError,
                              },attrs:{"items":_vm.visible_on_website_options,"label":_vm.showtrans('Visible on website'),"name":"visible_on_website","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Visible on website - This is the field use to control if the said car park is visible on the front end of the website or not. Its a quick way to hide the car park from search results while still making it available for third parties via APIs. Use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,3294546138),model:{value:(_vm.visible_on_website),callback:function ($$v) {_vm.visible_on_website=$$v},expression:"visible_on_website"}}),_c('v-textarea',{class:{
                                textdanger: _vm.description_hasError,
                              },attrs:{"outlined":"","name":"description","label":"Carpark Description","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Car Park Description - This is where you can enter some blurb about the car park. At this point this field is not used anywhere in the front end but can be used in the future if such a need arises. This field is not mandatory. ")])])]},proxy:true}],null,false,230963165),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{class:{ textdanger: _vm.status_hasError },attrs:{"items":_vm.status_options,"label":_vm.showtrans('Status'),"name":"status","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Status - This is used to activate/deactivate an entire car park and can completely end up stopping parking search on the front end. So be careful if you choose to de-activate. However if you are setting up the client in dev / uat mode then you are ok to tinker with this. If in live then use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,4121345271),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createcarpark}},[_vm._v(_vm._s(_vm.showtrans("Submit")))]),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.getcarpark}},[_vm._v(_vm._s(_vm.showtrans("Back")))])],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.showeditclient)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-content',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.showtrans("Edit Car Park")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-text-field',{class:{
                                textdanger: _vm.carpark_name_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Name'),"name":"carpark_name","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Name - The name of the car park. This field is mandatory. ")])])]},proxy:true}],null,false,4037389518),model:{value:(_vm.carpark_name),callback:function ($$v) {_vm.carpark_name=$$v},expression:"carpark_name"}}),_c('v-text-field',{class:{
                                textdanger: _vm.carpark_identifier_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Identifier'),"name":"carpark_identifier","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Identifier - The identifier of the carpark in most cases 3 or 4 digits. If this is an existing client then best to check if they already use an identifier for the car park in question and then use the same identifier. If they don't have one then please make up sensible identifiers. For example if they have a car park called Car Park 1 - perhaps CP1 is the simplest identifier as opposed to YU098MG847. Stick to the KISS principle when in doubt but it still in doubt ask the Rezcomm Team. This field is mandatory ")])])]},proxy:true}],null,false,888331308),model:{value:(_vm.carpark_identifier),callback:function ($$v) {_vm.carpark_identifier=$$v},expression:"carpark_identifier"}}),_c('v-text-field',{class:{
                                textdanger: _vm.total_parking_slots_hasError,
                              },attrs:{"label":_vm.showtrans('Total Parking Slots'),"name":"total_parking_slots","maxlength":"10","type":"number","min":"0","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" The number of parking slots at the car park. This field is mandatory and must be greater than 0. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2834967084),model:{value:(_vm.total_parking_slots),callback:function ($$v) {_vm.total_parking_slots=$$v},expression:"total_parking_slots"}}),_c('v-text-field',{class:{
                                textdanger: _vm.slot_duration_in_minutes_hasError,
                              },attrs:{"label":_vm.showtrans('Slot Duration in minutes'),"name":"slot_duration_in_minutes","maxlength":"10","type":"number","min":"0","readonly":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Slot duration in minutes - This is the minimum duration one can book parking for. Your options are 15/30/45/60 minutes. This is a mandatory field. Once this has been set this field cannot be changed or edited. This is a mandatory field. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2004837643),model:{value:(_vm.slot_duration_in_minutes),callback:function ($$v) {_vm.slot_duration_in_minutes=$$v},expression:"slot_duration_in_minutes"}}),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Entry Grace Time - Hours'),"name":"entry_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of entry for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,236656442),model:{value:(_vm.entry_grace_hours),callback:function ($$v) {_vm.entry_grace_hours=$$v},expression:"entry_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Entry Grace Time - Minutes'),"name":"entry_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual booked time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,1510013468),model:{value:(_vm.entry_grace_minutes),callback:function ($$v) {_vm.entry_grace_minutes=$$v},expression:"entry_grace_minutes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Exit Grace Time - Hours'),"name":"exit_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of exit for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2259308954),model:{value:(_vm.exit_grace_hours),callback:function ($$v) {_vm.exit_grace_hours=$$v},expression:"exit_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Exit Grace Time - Minutes'),"name":"exit_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2738900032),model:{value:(_vm.exit_grace_minutes),callback:function ($$v) {_vm.exit_grace_minutes=$$v},expression:"exit_grace_minutes"}})],1)],1),_c('v-select',{class:{
                                textdanger: _vm.visible_on_website_hasError,
                              },attrs:{"items":_vm.visible_on_website_options,"label":_vm.showtrans('Visible on website'),"name":"visible_on_website","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Visible on website - This is the field use to control if the said car park is visible on the front end of the website or not. Its a quick way to hide the car park from search results while still making it available for third parties via APIs. Use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,3294546138),model:{value:(_vm.visible_on_website),callback:function ($$v) {_vm.visible_on_website=$$v},expression:"visible_on_website"}}),_c('v-textarea',{class:{
                                textdanger: _vm.description_hasError,
                              },attrs:{"outlined":"","name":"description","label":"Carpark Description","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Car Park Description - This is where you can enter some blurb about the car park. At this point this field is not used anywhere in the front end but can be used in the future if such a need arises. This field is not mandatory. ")])])]},proxy:true}],null,false,230963165),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{class:{ textdanger: _vm.status_hasError },attrs:{"items":_vm.status_options,"label":_vm.showtrans('Status'),"name":"status","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Status - This is used to activate/deactivate an entire car park and can completely end up stopping parking search on the front end. So be careful if you choose to de-activate. However if you are setting up the client in dev / uat mode then you are ok to tinker with this. If in live then use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,4121345271),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updatecarpark}},[_vm._v(_vm._s(_vm.showtrans("Update")))]),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.getcarpark}},[_vm._v(_vm._s(_vm.showtrans("Back")))])],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.showdeleteclient)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-content',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.showtrans("Delete Car Parking Service")))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-text-field',{class:{
                                textdanger: _vm.carpark_name_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Name'),"name":"carpark_name","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Name - The name of the car park. This field is mandatory. ")])])]},proxy:true}],null,false,4037389518),model:{value:(_vm.carpark_name),callback:function ($$v) {_vm.carpark_name=$$v},expression:"carpark_name"}}),_c('v-text-field',{class:{
                                textdanger: _vm.carpark_identifier_hasError,
                              },attrs:{"label":_vm.showtrans('Carpark Identifier'),"name":"carpark_identifier","maxlength":"25"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Carpark Identifier - The identifier of the carpark in most cases 3 or 4 digits. If this is an existing client then best to check if they already use an identifier for the car park in question and then use the same identifier. If they don't have one then please make up sensible identifiers. For example if they have a car park called Car Park 1 - perhaps CP1 is the simplest identifier as opposed to YU098MG847. Stick to the KISS principle when in doubt but it still in doubt ask the Rezcomm Team. This field is mandatory ")])])]},proxy:true}],null,false,888331308),model:{value:(_vm.carpark_identifier),callback:function ($$v) {_vm.carpark_identifier=$$v},expression:"carpark_identifier"}}),_c('v-text-field',{class:{
                                textdanger: _vm.total_parking_slots_hasError,
                              },attrs:{"label":_vm.showtrans('Total Parking Slots'),"name":"total_parking_slots","maxlength":"10","type":"number","min":"0"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" The number of parking slots at the car park. This field is mandatory and must be greater than 0. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2834967084),model:{value:(_vm.total_parking_slots),callback:function ($$v) {_vm.total_parking_slots=$$v},expression:"total_parking_slots"}}),_c('v-text-field',{class:{
                                textdanger: _vm.slot_duration_in_minutes_hasError,
                              },attrs:{"label":_vm.showtrans('Slot Duration in minutes'),"name":"slot_duration_in_minutes","maxlength":"10","type":"number","min":"0"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Slot duration in minutes - This is the minimum duration one can book parking for. Your options are 15/30/45/60 minutes. This is a mandatory field. Once this has been set this field cannot be changed or edited. This is a mandatory field. This field once added is not editable via the edit page. ")])])]},proxy:true}],null,false,2004837643),model:{value:(_vm.slot_duration_in_minutes),callback:function ($$v) {_vm.slot_duration_in_minutes=$$v},expression:"slot_duration_in_minutes"}}),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Entry Grace Time - Hours'),"name":"entry_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of entry for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,236656442),model:{value:(_vm.entry_grace_hours),callback:function ($$v) {_vm.entry_grace_hours=$$v},expression:"entry_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.entry_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Entry Grace Time - Minutes'),"name":"entry_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Entry Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual booked time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,1510013468),model:{value:(_vm.entry_grace_minutes),callback:function ($$v) {_vm.entry_grace_minutes=$$v},expression:"entry_grace_minutes"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_hours_hasError,
                                  },attrs:{"items":_vm.timeinhours,"label":_vm.showtrans('Exit Grace Time - Hours'),"name":"exit_grace_hours"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Hours - This is the grace time \"hour\" allowed from their actual booked time of exit for parking to start. So if set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 9.00am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2259308954),model:{value:(_vm.exit_grace_hours),callback:function ($$v) {_vm.exit_grace_hours=$$v},expression:"exit_grace_hours"}})],1),_c('v-col',[_c('v-select',{class:{
                                    textdanger: _vm.exit_grace_minutes_hasError,
                                  },attrs:{"items":_vm.timeminutes,"label":_vm.showtrans('Exit Grace Time - Minutes'),"name":"exit_grace_minutes"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Exit Grace Time Minutes - This is the grace time \"minutes\" allowed from their actual time of entry for parking to start. So if set to 30 minutes with the above grace time hours set to 1 hour and the customer books for 10.00am. They will still be allowed to enter if they try to access parking at 8.30am without having to pay an additional fee. In some cases please note that this setting is managed at the CPMS level and if you are entering data for such a customer this value needs to be set to 0 and any grace will get added at CPMS end. This is a mandatory field. ")])])]},proxy:true}],null,false,2738900032),model:{value:(_vm.exit_grace_minutes),callback:function ($$v) {_vm.exit_grace_minutes=$$v},expression:"exit_grace_minutes"}})],1)],1),_c('v-select',{class:{
                                textdanger: _vm.visible_on_website_hasError,
                              },attrs:{"items":_vm.visible_on_website_options,"label":_vm.showtrans('Visible on website'),"name":"visible_on_website","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Visible on website - This is the field use to control if the said car park is visible on the front end of the website or not. Its a quick way to hide the car park from search results while still making it available for third parties via APIs. Use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,3294546138),model:{value:(_vm.visible_on_website),callback:function ($$v) {_vm.visible_on_website=$$v},expression:"visible_on_website"}}),_c('v-textarea',{class:{
                                textdanger: _vm.description_hasError,
                              },attrs:{"outlined":"","name":"description","label":"Carpark Description","rows":"5"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Car Park Description - This is where you can enter some blurb about the car park. At this point this field is not used anywhere in the front end but can be used in the future if such a need arises. This field is not mandatory. ")])])]},proxy:true}],null,false,230963165),model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{class:{ textdanger: _vm.status_hasError },attrs:{"items":_vm.status_options,"label":_vm.showtrans('Status'),"name":"status","item-text":"name","item-value":"code"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('div',{staticStyle:{"width":"50ch"}},[_vm._v(" Status - This is used to activate/deactivate an entire car park and can completely end up stopping parking search on the front end. So be careful if you choose to de-activate. However if you are setting up the client in dev / uat mode then you are ok to tinker with this. If in live then use with caution. This is a mandatory field. ")])])]},proxy:true}],null,false,4121345271),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.removecarpark}},[_vm._v(_vm._s(_vm.showtrans("Confirm Delete")))]),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.getcarpark}},[_vm._v(_vm._s(_vm.showtrans("Back")))])],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }