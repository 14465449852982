<template>
  <ul class="menu-nav">
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildrenreserv('/vue-bootstrap'),
      }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="reservation_parking">
        <i class="menu-icon icon-md fas fa-parking"></i>
        <span class="menu-text">Parking</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 12
              "
            >
              <a href="/parkingvenue.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Venue</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 12
              "
            >
              <a href="/parkingcpms.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage CPMS</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 10 &&
                (loggeduser_brandid == 'NWI' ||
                  loggeduser_brandid == 'HER' ||
                  loggeduser_brandid == 'NA')
              "
            >
              <a href="/carparkingservice.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Car Park</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 12
              "
            >
              <a href="/parkinglotproducts.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text" :style="showactiverezsubmenu === 'reservation_parking_manage_product' ? 'font-weight: bold !important;' : ''">Manage Product</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 12
              "
            >
              <a href="/yieldmanagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Management</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 11 &&
                loggeduser_groupid != 10 &&
                loggeduser_groupid != 12
              "
            >
              <a href="/yieldconfig.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Configure</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
              v-if="
                loggeduser_groupid != 7 &&
                loggeduser_groupid != 9 &&
                loggeduser_groupid != 10 &&
                (loggeduser_brandid == 'NWI' ||
                  loggeduser_brandid == 'HER' ||
                  loggeduser_brandid == 'NA')
              "
            >
              <a href="/parkingblackout.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Blackout</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="reservation_medscreening">
        <i class="menu-icon icon-md fas fa-briefcase-medical"></i>

        <span class="menu-text">Medical Screening</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medvenue.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Venue</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medlanes.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lanes</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medpaxtype.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pax Type</span>
              </a>
            </li>
          </router-link>
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medproduct.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Products</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medlanecapacity.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lane Capacity</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medtariff.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tariff</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medtariffdetails.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tariff Details</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/medlanecaptariff.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Lane Capacity Tariff</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-road"></i>
        <span class="menu-text">Toll</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Plaza</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/M6products.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">M6 Products</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 10 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 11
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="reservation_lounge">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-chair"></i>
        <span class="menu-text">Lounge</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/loungeVenueManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Venue Management</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/loungeCategoryManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Category Management</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/loungeSubCategoryManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">SubCategory Management</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/buttons"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/loungeProductsManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Product Management</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/calendars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/visitorManagement.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Visitor Management</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 10
      "
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        ref="security_immigration_fast_track"
      >
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-angle-double-right"></i>
        <span class="menu-text">Security &amp; Immigration Fast Track</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link to="" v-slot="{ isActive, isExactActive }">
            <li
              ref="security_immigration_fast_track_venue"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackvenue.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Venue</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttracklane.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Lane</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackproduct.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Products</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackpax.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Pax</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackrulesmaster.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Rules Master</span>
              </a>
            </li>
          </router-link>

          <router-link to="" v-slot="{ isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackvenurules.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Venue Rules</span>
              </a>
            </li>
          </router-link>

          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttracktax.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Tax</span>
              </a>
            </li>
          </router-link>

          <router-link to="" v-slot="{ isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttracktariff.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tariff</span>
              </a>
            </li>
          </router-link>

          <router-link to="" v-slot="{ isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttracktariffsegments.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tariff Segments</span>
              </a>
            </li>
          </router-link>

          <router-link to="" v-slot="{ isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/fasttrackschedule.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Schedule</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="promotions">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-percentage"></i>
        <span class="menu-text">Promotions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/managepromotions.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Promotions</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-inbox"></i>
        <span class="menu-text">Inbound Reservations</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inbound Res Sub 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inbound Res Sub 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inbound Res Sub 3</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/buttons"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inbound Res Sub 4</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/calendars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inbound Res Sub 5</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-ticket-alt"></i>
        <span class="menu-text">Bus Tickets</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tickets Submenu 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tickets Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tickets Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-taxi"></i>
        <span class="menu-text">Taxis</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Taxis Submenu 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Taxis Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Taxis Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle" ref="reservation_airportours">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-map-marked-alt"></i>
        <span class="menu-text">Airport Tours</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to=""
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a href="/airporttours.html" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Tours</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Airport Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Airport Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-users"></i>
        <span class="menu-text">Meeting Rooms</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Meeting Rooms 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Meeting Rooms 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Meeting Rooms 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-user-check"></i>
        <span class="menu-text">Subscriptions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Subscriptions Submenu 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Subscriptions Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Subscriptions Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildrenreserv('vuetify') }"
      v-if="
        loggeduser_groupid != 7 &&
        loggeduser_groupid != 9 &&
        loggeduser_groupid != 11 &&
        loggeduser_groupid != 12 &&
        loggeduser_groupid != 10
      "
    >
      <a href="#" class="menu-link menu-toggle">
        <!-- <i class="menu-icon flaticon-web"></i> -->
        <i class="menu-icon icon-md fas fa-chart-line"></i>
        <span class="menu-text">Yield Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/vuetify/alerts"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 1</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/avatars"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 2</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/vuetify/badges"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Yield Submenu 3</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>



<script>
export default {
  name: "KTReservationMenu",
  props: ["showactiverezmenu", "showactiverezsubmenu"],
  methods: {
    hasActiveChildrenreserv(match) {
      return false;
    },
  },
  data() {
    return {
      loggeduser_groupid: 0,
      loggeduser_brandid: 0,
    };
  },
  mounted() {
    //console.log(this.showactiverezmenu);
    this.$nextTick(() => {
      if (typeof this.showactiverezsubmenu !== "undefined") {
        setTimeout(() => {
          if (this.$refs[this.showactiverezsubmenu]) {
            this.$refs[this.showactiverezsubmenu].click();
          }
        }, 1000);
      }
      if (typeof this.showactiverezmenu !== "undefined") {
        setTimeout(() => {
          if (this.$refs[this.showactiverezmenu]) {
            this.$refs[this.showactiverezmenu].click();
          }
        }, 2000);
      }
    });

    let grpid = localStorage.getItem("group_id");
    grpid = grpid.replace('"', "");
    this.loggeduser_groupid = grpid.replace('"', "");
    let brndid = localStorage.getItem("brand_id");
    brndid = brndid.replace('"', "");
    this.loggeduser_brandid = brndid.replace('"', "");
  },
};
</script>
