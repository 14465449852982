<template>
	<div class="subheader py-3 py-lg-3 px-4 subheader-transparent" v-bind:class="subheaderClasses" id="kt_subheader">
		<div class="row">
			<div class="col-12 d-flex flex-column">
				<!--begin::Breadcrumb-->
				<ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold py-2 px-0 my-0 mx-5 border-bottom">
					<template v-for="(breadcrumb, i) in breadcrumbs">
						<li :key="`${i}`" class="breadcrumb-item">
							<span class="text-muted">
								{{ breadcrumb }}
							</span>
						</li>
					</template>
				</ul>
				<!--end::Breadcrumb-->
				<!--begin::Page Title-->
				<h2 class="subheader-title text-dark font-weight-bold py-2 px-0 my-0 mx-5">
					{{ title }}
				</h2>
				<!--end::Page Title-->
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'KTSubheader',
		props: {
			breadcrumbs: Array,
			title: String,
		},
		computed: {
			...mapGetters(['layoutConfig']),

			/**
			 * Check if subheader width is fluid
			 */
			widthFluid() {
				return this.layoutConfig('subheader.width') === 'fluid';
			},

			subheaderClasses() {
				const classes = [];
				const style = this.layoutConfig('subheader.style');
				if (style) {
					classes.push(style);

					if (style === 'solid') {
						classes.push('bg-white');
					}

					if (this.layoutConfig('subheader.fixed')) {
						classes.push('border-top');
					}

					if (this.layoutConfig('subheader.clear')) {
						classes.push('mb-0');
					}
				}
				return classes.join(' ');
			},
		},
	};
</script>
