var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/vue-bootstrap/card"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Single Admin Sub 1")])])])]}}],null,false,2764122867)}),_c('router-link',{attrs:{"to":"/vue-bootstrap/carousel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Single Admin Sub 2")])])])]}}],null,false,4114899472)}),_c('router-link',{attrs:{"to":"/vue-bootstrap/collapse"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Single Admin Sub 3")])])])]}}],null,false,1059837873)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"customer_information_management",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Customer Information Management")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/M6toll.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Customers")])])])]}}],null,false,2305915104)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/M6tollTransactions.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Transactions")])])])]}}],null,false,2542326326)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [(_vm.loggeduser_groupid != 9)?_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/globalusersearch.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Global User Search")])])]):_vm._e()]}}],null,false,3504788338)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/unblockuser.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Unblock User")])])])]}}],null,false,1053006710)}),_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"customer_information_management_gdpr",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(1),_c('span',{staticClass:"menu-text"},[_vm._v("GDPR")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [(_vm.loggeduser_groupid != 10 && _vm.loggeduser_groupid != 12)?_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/manualanon.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manual Anonymization")])])]):_vm._e()]}}],null,false,1950629393)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/anonlogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Anonymization Logs")])])])]}}],null,false,1185591788)})],1)])])],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 11
      )?_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-digital-marketing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Analytics")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics_email_logs",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(2),_c('span',{staticClass:"menu-text"},[_vm._v("Email Logs")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [(_vm.loggeduser_groupid != 10 && _vm.loggeduser_groupid != 12)?_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/sesdashboard.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("SES Dashboard")])])]):_vm._e()]}}],null,false,4014859755)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/analytics.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Email Campaign Logs")])])])]}}],null,false,4114412389)}),(_vm.loggeduser_groupid != 10 && _vm.loggeduser_groupid != 12)?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/sesanalytics.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Transactional Email Logs")])])])]}}],null,false,2515523319)}):_vm._e()],1)])]),(
              _vm.loggeduser_groupid != 10 &&
              _vm.loggeduser_groupid != 11 &&
              _vm.loggeduser_groupid != 12
            )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap/form'),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics_audit_logs",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(3),_c('span',{staticClass:"menu-text"},[_vm._v("Audit Logs")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/koweelogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Kowee Logs")])])])]}}],null,false,1105961110)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/refundlogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Refund Logs")])])])]}}],null,false,1849118742)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("SMS Logs")])])])]}}],null,false,3495476066)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/wsdllogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("CPMS Logs")])])])]}}],null,false,1054101367)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/peellogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Ipark FTP Logs")])])])]}}],null,false,4285932713)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/auditlogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Shop General")])])])]}}],null,false,1830000254)})],1)])]):_vm._e()])])]):_vm._e(),(_vm.loggeduser_groupid != 10)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"business_bi",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("BI")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"business_bi_operations",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(4),_c('span',{staticClass:"menu-text"},[_vm._v("Operations (PII)")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopfulfillreport.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Bookings")])]):_vm._e()])]}}],null,false,2086250958)}),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 14 &&
                    _vm.loggeduser_groupid != 13 &&
                    (_vm.loggeduser_brandid == 'NWI' ||
                      _vm.loggeduser_brandid == 'HER' ||
                      _vm.loggeduser_brandid == 'NA')
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/historicbookings.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Historic Bookings")])]):_vm._e()])]}}],null,false,3269375179)}):_vm._e(),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 14 &&
                    _vm.loggeduser_groupid != 13 &&
                    (_vm.loggeduser_brandid == 'NWI' ||
                      _vm.loggeduser_brandid == 'NA' ||
                      _vm.loggeduser_brandid == 'HER')
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 10
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/parkingbookings.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Bookings")])]):_vm._e()])]}}],null,false,3994833582)}):_vm._e(),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/Paymentlogs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("SIBS Payment Logs")])])])]}}],null,false,1189766429)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/downloadreports.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Download Reports")])])])]}}],null,false,3788603702)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/Weologs.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Weo Logs")])])])]}}],null,false,2755047222)}),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 14 &&
                    _vm.loggeduser_groupid != 13 &&
                    (_vm.loggeduser_brandid == 'NWI' ||
                      _vm.loggeduser_brandid == 'NA' ||
                      _vm.loggeduser_brandid == 'HER')
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 10
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/logscpms.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("CPMS Logs")])]):_vm._e()])]}}],null,false,640038214)}):_vm._e(),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/m6mepuat.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("MEP Orders")])]):_vm._e()])]}}],null,false,434883405)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/m6utr.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Payment Notices")])]):_vm._e()])]}}],null,false,3624912)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/m6tagsuat.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("I Buy Orders")])]):_vm._e()])]}}],null,false,3517448449)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/m6contactuat.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Contact Form Submissions")])]):_vm._e()])]}}],null,false,1709379959)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/M6tagsregnoupdate.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tag Reg Change Requests")])]):_vm._e()])]}}],null,false,952050525)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/M6tagsreplace.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tag Replace Requests")])]):_vm._e()])]}}],null,false,1738084747)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 13 &&
                        _vm.loggeduser_groupid != 14 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/M6tagsreqnew.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Tag Bracket Requests")])]):_vm._e()])]}}],null,false,2140179513)})],1)])])])])]):_vm._e(),(
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 13
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"reporting_forecasting",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Reporting & Forecasting (Non PII)")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                _vm.loggeduser_groupid != 8 &&
                _vm.loggeduser_groupid != 9 &&
                _vm.loggeduser_groupid != 10 &&
                _vm.loggeduser_groupid != 11 &&
                _vm.loggeduser_groupid != 12 &&
                _vm.loggeduser_groupid != 7
              )?_c('a',{ref:"reporting_forecasting_commercials",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(5),_c('span',{staticClass:"menu-text"},[_vm._v("Travel Log")]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/shopnoresultlog.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("No Result Log")])])])]}}],null,false,1616833138)})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(_vm.loggeduser_groupid != 9)?_c('a',{ref:"reporting_forecasting_commercials",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_vm._m(6),_c('span',{staticClass:"menu-text"},[_vm._v("Commercial")]),_c('i',{staticClass:"menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    (_vm.loggeduser_brandid == 'NWI' ||
                      _vm.loggeduser_brandid == 'NA' ||
                      _vm.loggeduser_brandid == 'HER')
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/parkingoccupancyreport.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Parking Occupancy Report")])]):_vm._e()])]}}],null,false,168943147)}):_vm._e(),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    (_vm.loggeduser_brandid == 'NWI' ||
                      _vm.loggeduser_brandid == 'NA' ||
                      _vm.loggeduser_brandid == 'HER')
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/parkingoccupancybirdseyeview.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Parking Occupancy Birds Eye View")])]):_vm._e()])]}}],null,false,2740321355)}):_vm._e(),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 12 &&
                    _vm.loggeduser_groupid != 11
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/shopcommreport.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Commercial Report")])])])]}}],null,false,2636931522)}):_vm._e(),(
                    _vm.loggeduser_groupid != 7 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 8 &&
                    _vm.loggeduser_groupid != 10 &&
                    _vm.loggeduser_groupid != 12 &&
                    _vm.loggeduser_groupid != 11
                  )?_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/shopcommreportv6.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Commercial Report V6")])])])]}}],null,false,2532673378)}):_vm._e(),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [(
                      _vm.loggeduser_groupid != 8 &&
                      _vm.loggeduser_groupid != 9 &&
                      _vm.loggeduser_groupid != 88 &&
                      _vm.loggeduser_groupid != 10 &&
                      (_vm.loggeduser_brandid == 'NWI' ||
                        _vm.loggeduser_brandid == 'NA')
                    )?_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/shopcommsnapshotv6.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Commercial Snapshot")])])]):_vm._e()]}}],null,false,1325648201)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopcommentryparking.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Entry Parking")])]):_vm._e()])]}}],null,false,2460796128)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopcommdstayoverview.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Stay Overview")])]):_vm._e()])]}}],null,false,1834534107)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopv5commsalesperf.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Sales Performance")])]):_vm._e()])]}}],null,false,544370661)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopv5commcancelperf.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Cancellation Report")])]):_vm._e()])]}}],null,false,645149314)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(
                        _vm.loggeduser_groupid != 8 &&
                        _vm.loggeduser_groupid != 9 &&
                        _vm.loggeduser_groupid != 10 &&
                        _vm.loggeduser_groupid != 11 &&
                        _vm.loggeduser_groupid != 7 &&
                        _vm.loggeduser_groupid != 12
                      )?_c('a',{staticClass:"menu-link",attrs:{"href":"/shopv5commlatency.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Trends")])]):_vm._e()])]}}],null,false,437825614)})],1)])])])])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(7),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/vuetify/alerts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Yield Submenu 1")])])])]}}],null,false,2159383752)}),_c('router-link',{attrs:{"to":"/vuetify/avatars"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Yield Submenu 2")])])])]}}],null,false,975515019)}),_c('router-link',{attrs:{"to":"/vuetify/badges"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Yield Submenu 3")])])])]}}],null,false,3682801098)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"business_languages",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Languages")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/languages.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Languages")])])])]}}],null,false,3897590774)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/translations.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Translations")])])])]}}],null,false,3301097942)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics_access",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Access")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/users.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])])])]}}],null,false,1532373334)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/usergroups.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("User Groups")])])])]}}],null,false,740875126)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/permissions.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("User Permissions")])])])]}}],null,false,989860263)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/apiusers.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("API Users")])])])]}}],null,false,760454262)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/apiurls.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("API URL(s)")])])])]}}],null,false,2816291095)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics_clients",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Clients")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/clients.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Clients")])])])]}}],null,false,673076566)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/brands.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Brands")])])])]}}],null,false,2987810518)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"marketing_analytics_clients",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contract Wiki")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/contracts.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Contract Wiki")])])])]}}],null,false,1918339001)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"business_partners",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Partners")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/partnersManagement.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Partner Management")])])])]}}],null,false,3963744997)}),_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/partnersBrandMapping.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Brand Product Mapping")])])])]}}],null,false,3092723648)})],1)])]):_vm._e(),(
        _vm.loggeduser_groupid != 7 &&
        _vm.loggeduser_groupid != 9 &&
        _vm.loggeduser_groupid != 10 &&
        _vm.loggeduser_groupid != 11 &&
        _vm.loggeduser_groupid != 13 &&
        _vm.loggeduser_groupid != 14 &&
        _vm.loggeduser_groupid != 12
      )?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{ref:"flowy_ui",staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("FlowyUI")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"/flowyUI.html"}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("FlowyUI")])])])]}}],null,false,2958433142)})],1)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Single Administration")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Yield Management")]),_c('i',{staticClass:"menu-arrow"})])}]

export { render, staticRenderFns }